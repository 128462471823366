import {configureStore} from '@reduxjs/toolkit'
import {AuthService} from "../service/LoginService";
import {DateService} from "../service/Date";
import {NotificationService} from "../service/Notification";
import {PushService} from "../service/Push";
import {EventsService} from "../service/EventsService";
import {CurrentUserService} from "../service/CurrentUserService";
import {SubscribedService} from "../service/SubscribedService";
import {EditFormService} from "../service/EditFormService";
import {TagsService} from "../service/TagsService";
import {HolidaysService} from "../service/HolidaysService";

export const store = configureStore({
  reducer: {
    auth: AuthService.reducer,
    dt: DateService.reducer,
    notifications: NotificationService.reducer,
    push: PushService.reducer,
    events: EventsService.reducer,
    currentUser: CurrentUserService.reducer,
    subscribed: SubscribedService.reducer,
    editForm: EditFormService.reducer,
    tags: TagsService.reducer,
    holidays: HolidaysService.reducer,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
