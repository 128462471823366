import {createSlice} from "@reduxjs/toolkit";
import {AuthenticationService} from "./LoginService";
import moment from "moment/moment";

export interface ISubEvent {
  id: number,
  dt: {date: string},
  title: string,
  uid: string,
  dt_start: string,
  dt_end: string,
  freq: boolean,
  freq_config: {freq: string, until: string, interval: number}
  important: number,
  description: string,
  calendar: number
}

interface ISubCalendar {
  active: boolean
  user: number,
  title: string,
  link: string,
  events: ISubEvent[],
  color: string,
  outer: boolean
}

export const SubscribedService = createSlice({
  name: 'SubscribedService',
  initialState: <{calendars: ISubCalendar[]}>{
    calendars: []
  },
  reducers: {
    setCalendars(state, action){
      state.calendars = action.payload;
      const parseDate = (dt: string) => {
        const dtArray = dt.split('T');
        return {date: moment(dtArray[0], 'YYYY-MM-DD').format('DD.MM.YYYY'), time: dtArray[1].slice(0, 5)};
      }
      if (state.calendars.length === 0) return;
      state.calendars = action.payload.map((calendar: ISubCalendar) => {
        return {...calendar, events: calendar.events.map((event: ISubEvent) => {
          return {...event, dt: parseDate(event.dt_start), color: calendar.color,
            time_end: event?.dt_end?.length > 0 ? parseDate(event.dt_end) : '',
          }})}
      })
    },
    setCalendarsFormatted(state, action) {
      state.calendars = action.payload;
    }
  }
})

export const {setCalendars, setCalendarsFormatted} = SubscribedService.actions;

