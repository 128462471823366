import React from 'react';
import './LeftPanel.scss';
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";
import Filter from "./Filter/Filter";
import FilterUser from "./Filter/FilterUser";
import FilterCalendars from "./Filter/FilterCalendars";


const LeftPanel = () => {
  const dt = moment().format('DD.MM.YYYY');

  return (
    <div className={'LeftPanel__Wrapper'}>
        <div className="DatePicker">
          <DatePicker dt={dt}/>
        </div>
        <Filter />
        <FilterCalendars />
        <FilterUser />
    </div>
  );
}

export default LeftPanel;
