import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Month from "./Month/Month";
import Week from "./Week/Week";
import Day from "./Day/Day";
import Year from "./Year/Year";
import {clearForm} from "../../../service/EditFormService";
import {RootState} from "../../../store/store";
import moment from "moment";
import DaysService, {DateFormat} from "../../../service/DaysService";
import day from "./Day/Day";

const Grid = () => {
  const selected = useSelector((state: any) => state.dt);
  let {daysStore, mode} = selected;
  const daysArray = daysStore.map(d => d.dt);
  const events = useSelector((state: RootState) => state.events.events)
    .filter(ev => daysArray.includes(ev.dt.date) || ev.freq);
  const calendarsDefault = useSelector((state: RootState) => state.subscribed.calendars);
  const calendars = calendarsDefault.map(calendar => {
    return {...calendar, events: calendar.events.filter(ev => daysArray.includes(ev.dt.date))}
  }).filter((cal: any) => cal.active)
    .reduce((prev: any, curr: any) => prev.concat(curr.events), []);
  const [eventsByDate, calendarsEvents] = getEventsByDate();

  const [state, setState] = useState({
    current_mode: null
  })

  useEffect(() => {
    if (!state.current_mode || state.current_mode.value !== mode.value) {
      setState({...state, current_mode: mode});
    }
    dispatch(clearForm())
  }, [mode])

  function getEventsByDate(){
    const byDate = {};
    events.filter(ev => !ev.freq).map(ev => {
      if (!byDate[ev.dt.date]) byDate[ev.dt.date] = [ev];
      else byDate[ev.dt.date] = [...byDate[ev.dt.date]].concat([ev]);
    })

    const calendarByDate = {};
    calendars.filter(ev => !ev.freq).map(ev => {
      if (!calendarByDate[ev.dt.date]) calendarByDate[ev.dt.date] = [ev];
      else calendarByDate[ev.dt.date] = [...calendarByDate[ev.dt.date]].concat([ev]);
    })
    return [byDate, calendarByDate]
  }

  function filterFreqEvents(mode){
    console.log(mode)
    const eventsFreq = events.filter(ev => ev.freq);
    const freq_events = calendarsDefault
      .filter(cal => cal.active)
      .map(cal => cal.events.filter(ev => ev.freq))
      .reduce((prev, cur) => prev.concat(cur),[])
    let freqEvTMP = {};
    const yearDays = DaysService.getYearsDays(moment(selected.dt, DateFormat).format('YYYY'));
    const selectedDays = mode.value !== 'year' ? daysArray : yearDays;
    selectedDays.map(dt => {
      const dayOfWeek = moment(dt, DateFormat).isoWeekday();
      const getEventsByFreq = (freq) => {
        // @ts-ignore
        return freq_events.concat(eventsFreq).filter(ev =>
          (freq === 'week' ? moment(ev.dt.date, DateFormat).isoWeekday() === dayOfWeek : true)
          && ev.freq
          && ev.freq_config.freq === (freq === 'week' ? 'weekly' : 'daily')
          && (moment(ev.freq_config.until, 'YYYY-MM-DD').isAfter(moment(dt, DateFormat)) || moment(ev.freq_config.until, 'YYYY-MM-DD').isSame(moment(dt, DateFormat)) || !ev.freq_config.until)
          && (Math.abs(moment(ev.dt.date, DateFormat).diff(moment(dt, DateFormat), freq === 'week' ? 'weeks' : 'days')) % ev.freq_config.interval === 0 || ! !!ev.freq_config.interval)
          && (moment(ev.dt.date, DateFormat).isBefore(moment(dt, DateFormat)) || ev.dt.date === dt))
      }
      freqEvTMP[dt] = [...getEventsByFreq('week'), ...getEventsByFreq('day')]
    });
    let freqEv = {}
    selectedDays.map(date => {
      if (freqEvTMP[date].length !== 0) freqEv[date] = freqEvTMP[date];
    })
    return freqEv;
  }

  const dispatch = useDispatch();

  function getWeek() {
    let weekFilter
    for (let i = 1; i < 7; i++) {
      let start = i === 1 ? 0 : 7 * i - 7;
      let end = i === 1 ? 7 : 7 * i;
      let week = daysStore.slice(start, end);
      if(!weekFilter) weekFilter = week
      for (let day in week) {
        if (week[day].dt === selected.dt) {
          weekFilter = week
        }
      }
    }
    return weekFilter
  }

  function switchMode(mode:{title: string, value: string}) {
    if (!mode) return <></>;
    const freqEvents = filterFreqEvents(mode);
    if(mode.value == 'year') {
      return <Year freqEvents={freqEvents} calendars={calendarsEvents} events={eventsByDate} selected={selected}/>
    } else if(mode.value == 'month') {
      return <Month freqEvents={freqEvents}/>
    } else if(mode.value == 'week'){
      return <Week freqEvents={freqEvents} calendars={calendarsEvents} events={eventsByDate} selected={selected} week={getWeek()}/>
    } else {
      return <Day freqEvents={freqEvents} calendars={calendarsEvents} events={eventsByDate} selected={selected}/>
    }
  }
  return switchMode(state.current_mode)
};

export default Grid;
