import React, {useEffect, useState} from 'react';
import './Push.scss'
import {useDispatch} from "react-redux";
import {IDetail, removePush} from "../../../service/Push";

const PushPanel = (props: any) => {
  const {detail} = props;
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(removePush(detail))
    }, 5000)
  }, [])

  return (
    <div className={`PushWrapper`}>
      <div className={'line'}></div>
      <div className={'PushText'}>
        <span>{detail.detail}</span>
      </div>
    </div>
  );
};

export default PushPanel;
