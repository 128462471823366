import React, {useEffect, useState} from 'react';
import './DropDown.scss';
import {useDispatch, useSelector} from "react-redux";
import {setMode} from "../../../service/Date";
import {AuthenticationService} from "../../../service/LoginService";
import {RootState} from "../../../store/store";

interface IMode {
  value: string,
  title: string
}

interface IProps {
  currentMode?: IMode;
  availableModes: IMode[],
  change?: (mode: {value: string, title: string}) => void,
  notDispatch?: boolean
}

interface IState extends IProps {
  isVisible: boolean
}

const Slide = (props: IProps) => {
  const [state, setState] = useState<IState>({
    currentMode: {value: '', title: ''},
    availableModes: [],
    isVisible: false,
  })

  const dispatch = useDispatch();

  useEffect(() => {
    setState({...state, currentMode: props.currentMode, availableModes: props.availableModes})
  }, [props.currentMode]);


  function onChange(mode: {value: string, title: string}, e: any){
    setState({...state, currentMode: mode, isVisible: false});
    if (props.notDispatch) {
      props.change(mode);
      return;
    }
    dispatch(setMode(mode.value));
  }

  return (
    <div className={'Slider'}>
      <div className="SliderWrapper">
          {state.availableModes.map(mode => (
              <div key={mode.title}>
                <span className={`SliderOption${state.currentMode?.title === mode.title ? ' active' : ''}`}
                      onClick={(e) => onChange(mode, e)}>
              {mode.title}
            </span>
              </div>
          ))}
      </div>
    </div>
  );
};

export default Slide;
