import React, {useEffect, useState} from 'react';
import './EditForm.scss';
import {useDispatch, useSelector} from "react-redux";
import {clearForm, FormService, setModelForm, setVisible} from "../../../service/EditFormService";
import {RootState} from "../../../store/store";
import moment from "moment";
import Members from "./Members";
import {setEvents} from "../../../service/EventsService";
import {AuthenticationService} from "../../../service/LoginService";
import InputWithSearch from "./InputWithSearch";
import ColorSelect from "./ColorSelect";
import {cn} from "../../../service/Calendar";
import {setTags} from "../../../service/TagsService";

const Switch = ({visible, change}) => {
  return (
    <div onClick={() => change(!visible)} className={`Switch${visible ? ' on' : ' off'}`}>
      <div className="Ball"></div>
    </div>
  )
}

const ValidateInput = ({value, onChange, type, validate}) => {
  return (
    <input className={cn('ValidInput', {notValidate: !validate})}
           value={value}
           onChange={onChange}
           type={type}/>
  )
}


const EditForm = () => {
  const [state, setModel] = useState({
    permission_level: 1,
    title: '',
    id: null,
    dt: '',
    important: 0,
    time_begin: '',
    time_end: '',
    tag: null,
    freq: false,
    freq_config: {freq: 'weekly', until: null, interval: 0},
    tag_color: '#9797C4',
    location: '',
    comment: '',
    members: [],
    tagSelected: null
  });

  function setState(obj) {
    dispatch(setModelForm({...obj, tag: obj.tagSelected}));
    setModel(obj);
  }

  const [validate, setValidate] = useState({
    dt: false,
    time_begin: false,
    time_end: false,
  });

  const [allUsers, setAllUsers] = useState([]);
  const formService = useSelector((state: RootState) => state.editForm);
  const AuthService = AuthenticationService.getInstance();
  const tags = useSelector((state: RootState) => state.tags).tags;

  useEffect(() => {
    AuthService.getUsers().then(res => {
      setAllUsers(res.data);
    });
  }, [])


  useEffect(() => {
    const keys = ['dt', 'time_begin', 'time_end']
      .filter(key => state[key].length > 0 && state[key] !== 'Invalid date')
      .reduce((prev, curr) => prev = {...prev, [curr]: true}, {});
    setValidate({...validate, ...keys});
  }, [state.dt, state.time_begin, state.time_end])


  useEffect(() => {
    const currentDt = moment(formService.currentDt, 'DD.MM.YYYY').format('YYYY-MM-DD');
    const timeBegin = moment(formService.startDt, 'DD.MM.YYYY HH:mm').format('HH:mm') || '';
    const timeEnd = moment(formService.endDt, 'DD.MM.YYYY HH:mm').format('HH:mm') || '';
    setState({...state, dt: currentDt, time_begin: timeBegin, time_end: timeEnd});
  }, [formService.currentDt, formService.startDt, formService.endDt]);

  useEffect(() => {
    let model = {};

    const validFormat = (time) => time.length === 4 ? '0' + time : time;

    const time = {
      startDt: formService.startDt ? validFormat(formService.startDt.split(' ')[1]) : '',
      endDt: formService.endDt ? validFormat(formService.endDt.split(' ')[1]) : '',
    };

    if (!formService.currentEvent) {
      model = {
        ...model,
        permission_level: 1,
        title: '',
        id: null,
        dt: moment(formService.currentDt, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        important: 0,
        time_begin: time.startDt,
        time_end: time.endDt,
        tag: null,
        freq: false,
        freq_config: {freq: 'weekly', until: null, interval: 0},
        tag_color: '#9797C4',
        location: '',
        comment: '',
        members: [],
        tagSelected: null
      }
    } else {
      model = {
        ...formService.currentEvent,
        tagSelected: formService.currentEvent.tag,
        title: formService.currentEvent.title.length === 0 ? '(Без названия)' : formService.currentEvent.title,
        time_begin: formService.currentEvent.dt.time,
        time_end: formService.currentEvent.time_end.time,
        dt: moment(formService.currentEvent.dt.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      }
    }
    setState({...state, ...model})
  }, [formService.currentEvent]);

  const dispatch = useDispatch();

  function onCancel() {
    dispatch(clearForm());
    dispatch(setModelForm(null))
  }

  function onTagChange(value) {
    const tag = tags.find(tag => tag.title === value) || null;
    const tagColor = tag?.color || state.tag_color;
    setState({...state, tag: value, tag_color: tagColor, tagSelected: tag});
  }

  const onChange = (e, key) => {
    if (key === 'time_end') {
      const time_end = moment(e.target.value, 'HH:mm');
      const time_begin = moment(state.time_begin, 'HH:mm');
      const newTime = time_end.isBefore(time_begin)
        ? time_begin.add(5, 'minutes').format('HH:mm')
        : time_end.format('HH:mm')

      setState({...state, [key]: newTime});
    } else {
      setState({...state, [key]: e.target.value});
    }
  }

  async function addEvent(method: string) {
    let canMakeRequest = true;
    Array.from(Object.keys(validate)).map(key => {
      if (!validate[key]) canMakeRequest = false;
    })
    if (!canMakeRequest) return;
    const tag = state.tag === null ? '' : typeof state.tag === 'string' ? state.tag : state.tag.id;
    let members = state.members?.map(user => typeof user === 'number' ? user : user.id) || []
    if (formService.currentEvent) {
      members = members.includes(formService.currentEvent.owner) ? members : members.concat([formService.currentEvent.owner])
    }
    AuthService.makeRequest('/api/events/', 'POST', {
      action: method,
      pk: state.id,
      title: state.title,
      dt: String(state.dt) + 'T' + String(state.time_begin),
      important: !!state.important ? state.important : 0,
      location: state.location,
      dt_end: String(state.dt) + 'T' + String(state.time_end),
      freq: state.freq,
      freq_config: state.freq ? state.freq_config : {freq: null, until: null, interval: null},
      members,
      tag,
      tag_color: state.tag_color,
      permission_level: state.permission_level,
      comment: state.comment,
    }).then((res: any) => {
      AuthService.makeRequest('/api/tags/').then((res) => dispatch(setTags(res.data)));
      dispatch(setEvents(res.data));
      dispatch(setVisible(false));
    }).then(() => dispatch(clearForm()));
  }

  return (
    <div className={'EditForm__Wrapper'}>
      <div className="Header">
        <span className="Title">{formService.currentEvent ? 'Редактирование события' : 'Создание события'}</span>
        <div className={'Toggle'}>
          <label htmlFor="">Видно всем</label>
          <Switch change={(value) => setState({...state, permission_level: value ? 2 : 1})}
                  visible={state.permission_level === 2}/>
        </div>
      </div>
      <div className="RowInput">
        <label htmlFor="">Название</label>
        <input value={state.title} onChange={(e) => onChange(e, 'title')} type="text"/>
      </div>
      <div className="DoubleRow">
        <div className="DoubleRow__Input">
          <label htmlFor="">Дата</label>
          <ValidateInput onChange={(e) => onChange(e, 'dt')}
                         value={state.dt}
                         type={'date'}
                         validate={validate.dt}
          />
        </div>
        <div className="DoubleRow__Input">
          <label htmlFor="">Приоритет</label>
          <select value={state.important}
                  onChange={(e) => setState({...state, important: Number(e.target.value)})}
          >
            <option value="0">0 (Обычный)</option>
            <option value="1">1 (Средний)</option>
            <option value="2">2 (Выше среднего)</option>
            <option value="3">3 (Высокий)</option>
          </select>
        </div>
      </div>
      <div className="DoubleRow">
        <div className="DoubleRow__Input">
          <label htmlFor="">С</label>
          <ValidateInput value={state.time_begin}
                         onChange={(e) => onChange(e, 'time_begin')}
                         validate={validate.time_begin}
                         type={'time'}/>
        </div>
        <div className="DoubleRow__Input">
          <label htmlFor="">По</label>
          <ValidateInput value={state.time_end}
                         onChange={(e) => onChange(e, 'time_end')}
                         type={'time'}
                         validate={validate.time_end}
          />
        </div>
      </div>
      <div className="RowInput">
        <label htmlFor="">Периодичность</label>
        <Switch change={(value) => setState({...state, freq: value})}
                visible={state.freq}/>
      </div>
      {state.freq &&
          <>
              <div className="DoubleRow">
                  <div className="DoubleRow__Input">
                      <label htmlFor="">Тип периода</label>
                      <select
                          onChange={(e) => setState({
                            ...state,
                            freq_config: {...state.freq_config, freq: e.target.value}
                          })}
                          value={state.freq_config.freq}>
                          <option value="weekly">Неделя</option>
                          <option value="daily">День</option>
                      </select>
                  </div>
                  <div className="DoubleRow__Input">
                      <label htmlFor="">Интервал</label>
                      <input value={state.freq_config.interval}
                             onChange={(e) =>
                               setState({
                                 ...state,
                                 freq_config: {...state.freq_config, interval: Number(e.target.value)}
                               })}
                             type={'number'}
                      />
                  </div>
              </div>
              <div className="RowInput">
                  <label htmlFor="">До</label>
                  <input
                      onChange={(e) => setState({...state, freq_config: {...state.freq_config, until: e.target.value}})}
                      value={state.freq_config.until}
                      type="date"/>
              </div>
          </>
      }
      <div className="RowInput">
        <label htmlFor="">Тег</label>
        <InputWithSearch value={state.tag?.title}
                         onChange={onTagChange}>
          <ColorSelect
            disabled={state.tagSelected}
            selected={state.tag?.color ? state.tag.color : state.tag_color}
            onChange={(color) => setState({...state, tag_color: color})}
          />
        </InputWithSearch>
      </div>
      <Members list={state.members} owner={formService.currentEvent?.owner} allUsers={allUsers}
               onChange={(value) => setState({...state, members: value})}/>
      <div className="RowInput">
        <label htmlFor="">Место проведения</label>
        <input value={state.location} onChange={(e) => onChange(e, 'location')} type="text"/>
      </div>
      <div className="RowInput">
        <label htmlFor="">Комментарий</label>
        <input value={state.comment} onChange={(e) => onChange(e, 'comment')}/>
      </div>
      <div className="ButtonGroup">
        <span onClick={onCancel} className={'Button'}>ОТМЕНА</span>
        {formService.currentEvent
          ? <span onClick={() => addEvent('put')} className={'Button'}>СОХРАНИТЬ</span>
          : <span onClick={() => addEvent('post')} className={'Button'}>СОЗДАТЬ</span>
        }

      </div>
    </div>
  );
};

export default EditForm;