import {createSlice} from "@reduxjs/toolkit";
import {AuthenticationService} from "./LoginService";
import moment from "moment/moment";

interface IUser {
  username: string,
  first_name: string,
  last_name: string,
  email: string,
  id: number,
  active: boolean
}

export const CurrentUserService = createSlice({
  name: 'CurrentUserService',
  initialState: <{user?: IUser}>{},
  reducers: {
    setCurrentUser(state, action){
      state.user = action.payload
    },
    clearCurrentUser(state) {
      state.user = undefined;
    }
  }
})

export const {setCurrentUser, clearCurrentUser} = CurrentUserService.actions;

