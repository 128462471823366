import React, {useEffect, useState} from "react";
import {AuthenticationService} from "../../../../service/LoginService";
import LabeledInput from "../../LabeledInput/LabeledInput";


export const SubLinkPanel = (props: {toggle: () => void}) => {
  const [state, setState] = useState<{
    link: string,
    copied: boolean
  }>({
    link: '',
    copied: false
  });

  const AuthService = AuthenticationService.getInstance();
  const {toggle} = props;

  useEffect(() => {
    AuthService.makeRequest('/api/share_link/').then((res: any) => {
      const hash = res.data.hash;
      let url = new URL(hash);
      const currentUrl = window.location;
      url.host = currentUrl.host;
      if (process.env.NODE_ENV !== 'development') url.port = '80';
      setState({...state, link: url.href})
    }).catch((e: any) => e.rresponse);
  }, [])

  function copy(e: any) {
    e.target.select()
    navigator.clipboard.writeText(state.link)
    setState({...state, copied: true})
  }

  return (
      <div className="Wrapper">
        <div className="Title">Поделиться ссылкой</div>
        <LabeledInput title={'Ссылка'} value={state.link} onClick={copy} />
        <div className="Tips">{state.copied ? 'Скопировано' : ''}</div>
      </div>
  );
};