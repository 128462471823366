import React from 'react';
import './Serach.scss';
import SVG from "../../SVG/SVG";


const Search = ({onChange}) => {
    return (
        <div className={'Search'}>
            <SVG type={'lens'} width={'1.5rem'} height={'1.5rem'} />
            <input onChange={onChange} placeholder={'Поиск'} className={'SearchInput'} type="text"/>
        </div>
    );
};

export default Search;