import {createSlice} from "@reduxjs/toolkit";
import {IEvent} from "../views/components/Grid/Modal/Modal";
import axios from "axios";


export interface IEditFormState {
    visible: boolean,
    currentEvent: any,
    currentDt: null | string,
    subscriptions?: any[],
    startDt: null | string,
    endDt: null | string,
    model: any,
    mode: string | null
}

export const FormService = (function(): any {
    let _instance = null;
    let state = <IEditFormState>{
        visible: false,
        currentEvent: null,
        currentDt: null,
        subscriptions: []
    }
    let subscribes: any = [];

    function setState(options: any){
        state = {...state, ...options};
        setModelForm(options);
        const newState = delete state['subscriptions'];
        if (subscribes.length > 0){
            subscribes.map((callback: any) => callback(state))
        }
    }


    const getModel = () => {
        return state;
    }

    function subscribeUpdates(callback: any){
        const strSubscribes = subscribes.map((func: any) => `${func}`)
        if (!strSubscribes.includes(`${callback}`)) subscribes.push(callback);
    }

    const createInstance = () => {
        return {
            state,
            setState,
            getModel,
            subscribeUpdates
        }
    }

    return {
        getInstance: function (){
            return _instance || (_instance = createInstance())
        }
    }
})();



export const EditFormService = createSlice({
    name: 'EditFormService',
    initialState: <IEditFormState>{
        visible: false,
        currentEvent: null,
        currentDt: null,
        startDt: null,
        endDt: null,
        model: null,
        mode: null
    },
    reducers: {
        clearForm(state){
          state.visible = false;
          state.currentDt = null;
          state.currentEvent = null;
          state.startDt = null;
          state.endDt = null;
        },
        setVisible(state, action){
            state.visible = action.payload;
        },
        setCurrentEvent(state, action){
            state.currentEvent = action.payload
        },
        setCurrentDt(state, action){
            state.currentDt = action.payload
        },
        setStartDt(state, action){
            state.startDt = action.payload
        },
        setEndDt(state, action){
            state.endDt = action.payload
        },
        setForm(state, action){
            Array.from(Object.keys(action.payload)).map((key: string) => {
                state[key] = action.payload[key]
            })
        },
        setModelForm(state, action){
            state.model = action.payload;
        },
        setFormMode(state, action){
            state.mode = action.payload
        }
    }
})

export const {
    setVisible,
    setCurrentEvent,
    setCurrentDt,
    setStartDt,
    setEndDt,
    clearForm,
    setForm,
    setModelForm,
    setFormMode
} = EditFormService.actions;

