import {createSlice} from "@reduxjs/toolkit";
import {IEvent} from "../views/components/Grid/Modal/Modal";
import axios from "axios";


export interface ITagService {
    tags: {
        title: string,
        color: string,
        id: number
    }[]
}


export const TagsService = createSlice({
    name: 'EditFormService',
    initialState: <ITagService>{
        tags: []
    },
    reducers: {
        setTags(state, action){
            state.tags = action.payload;
        },
    }
})

export const {setTags} = TagsService.actions;

