import {useDispatch, useSelector} from "react-redux";
import {AuthenticationService} from "../../../../service/LoginService";
import React, {useEffect, useState} from "react";
import {setNotification} from "../../../../service/Notification";
import moment from "moment/moment";
import {setDt, setMode} from "../../../../service/Date";
import {cn} from "../../../../service/Calendar";
import SVG from "../../SVG/SVG";
import {formatDate} from "../HeaderPanel";

export interface INotification {
  id: number,
  created: string,
  detail: string,
  checked: {
    user_id: number,
    checked: boolean,
    detail: string
  }[]
}

export const NotificationPanel = ({events, auditChange, toggleAudits}: {
  events: any, auditChange: (audits: INotification[]) => void, toggleAudits: (value: boolean) => void
}) => {
  const notifications = useSelector((state: { notifications: any }) => state.notifications).eventsStore;
  const AuthService = AuthenticationService.getInstance();
  const user = AuthService.getModel().user;
  const dispatch = useDispatch();
  const [state, setState] = useState<{
    events: INotification[], contextMenuVisible: boolean,
    coord: { x: number, y: number }, currentEvent?: INotification,
    contextMenuId: number
  }>({
    events: [],
    contextMenuVisible: false,
    coord: {x: 0, y: 0},
    contextMenuId: 0,
  })

  useEffect(() => {
    filterEvents(notifications);
  }, [notifications])

  function filterEvents(events: INotification[]) {
    const eventsList = events.map((event: INotification) => {
      const eventDetail = event.checked.find((id) => id.user_id == user.id)?.detail || event.detail;
      return {...event, created: formatDate(event.created), detail: eventDetail}
    }).sort((a: any, b: any) => {
      return b.id - a.id;
    }).filter(event => !!event.checked.find(check => check.user_id === user.id))
    auditChange(eventsList);
    dispatch(setNotification(notifications));
    setState({...state, events: eventsList, contextMenuVisible: false})
  }


  function clearAllNotifications(e, del: boolean = false, id = null){
    if (!events.length || e.target.className === 'AuditEvent') return;
    AuthService.makeRequest('/api/audits/', 'POST', {
      action: 'post',
      id_array: id ? [id] : events.map((ev: any) => ev.id),
      del
    }).then((res: any) => {
      const audits = res.data.filter((au: any) => au.checked.find((ch: any) => ch.user_id === user.id))
      filterEvents(audits);
      dispatch(setNotification(audits));
    })
  }

  function formatCreatedTime(dt){
    const format = 'DD.MM.YYYY HH:mm';
    const calcDiff = (unit) => Math.abs(moment(dt, format).diff(moment(), unit));
    const dtDiff = {
      seconds: calcDiff('seconds'),
      minutes: calcDiff('minutes'),
      hours: calcDiff('hours'),
    }
    if (dtDiff.seconds < 60) return 'Только что';
    else if (dtDiff.minutes < 60) return dtDiff.minutes + ' мин.';
    else if (dtDiff.hours < 24) return dtDiff.hours + ' час.';
    else return dt;
  }

  function showEvent(event){
    dispatch(setDt(moment(event.dt, 'YYYY-MM-DD').format('DD.MM.YYYY')));
    dispatch(setMode('day'));
    toggleAudits(false);
  }

  function makeNotificationText(audit){
    if (!audit.event) return audit.detail;
    else {
      const withoutTitle = '(Без названия)';
      let title = audit.event.title;
      if (title.length === 0){
        title = withoutTitle;
      }
      return (<div style={{display: "flex"}}>
            <span>{audit.detail.replace(title, '')}
              <span className={'AuditEvent'} onClick={() => showEvent(audit.event)}>{title}</span>
            </span>
      </div>)
    }
  }

  function contextMenu(e, event){
    e.preventDefault();
    setState({...state, contextMenuId: state.contextMenuId === event.id ? 0 : event.id});
  }

  return (
    <div className={'NotificationPanel shadow'} contextMenu={'none'}>
      <div className={'NotificationPanel__Buttons'}>
        <div onClick={(e) => clearAllNotifications(e,true)}>Очистить все</div>
        <div onClick={(e) => clearAllNotifications(e)}>Прочитать все</div>
      </div>
      <div className={'NotificationsList'}>
        {state.events.map((event: any) => (
          <div onContextMenu={(e) => contextMenu(e, event)}
               className={cn('ListItem', {
                 unread: !event.checked.find((id: any) => id.user_id === user.id)?.checked,
                 contextMenu: state.contextMenuId === event.id
               })}>
            <div className={'ListItem__Button'}
                 onClick={(e) => clearAllNotifications(e,false, event.id)}
                 onDoubleClick={(e) => clearAllNotifications(e,true, event.id)}
            >
              <div className={'Icon'}>
                {!event.checked.find((id: any) => id.user_id === user.id)?.checked &&
                    <SVG type={'notification'} width={'1.5rem'} height={'1.5rem'}/>}
              </div>
              <div>{makeNotificationText(event)}</div>
              <div className={'dt_event'}>{(formatCreatedTime(event.created))}</div>
            </div>
            {event.id === state.contextMenuId &&
                <div className={'removePanel'}>
                    <SVG type={'trash'}
                         width={'1rem'}
                         height={'1rem'}
                         onClick={(e) => clearAllNotifications(e,true, event.id)}
                    />
                </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
};
