import React, {useEffect, useState} from 'react';
import './ColorSelect.scss';
import SVG from "../SVG/SVG";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";

const ColorSelect = ({onChange, selected, disabled}) => {
    const def = '#9797C4';

    const [state, setState] = useState({
        colorList: [
            '#9797C4', '#66A3FF', '#64CAF6', '#92E103', '#E2D704', '#FF88F3',
            '#8B2A81', '#0F6CF9', '#97BCC4', '#00AC7D', '#F0BA69', '#F80077',
            '#9400B9', '#034CBA', '#039ECF', '#2EAB50', '#E59926', '#B90059',
            '#30003B', '#305897', '#00ABAB', '#016D50', '#F87700', '#F80F00'
        ],
        selected: '',
        isListVisible: false
    });

    const formService = useSelector((state: RootState) => state.editForm);

    useEffect(() => {
        setState({...state, selected: formService.currentEvent?.tag_color || selected || def})
    }, [formService.currentEvent])

    useEffect(() => {
        setState({...state, selected})
    }, [selected])

    function onColorPick(color) {
        setState({...state, isListVisible: !state.isListVisible});
        onChange(color)
    }

    return (
        <div className={'ColorSelect'}>
            <div className="SelectedColor" onClick={() => setState({...state, isListVisible: !state.isListVisible})}
                 style={{backgroundColor: selected}}></div>
            {state.isListVisible && !disabled &&
                <div className="ColorsList__Wrapper">
                    <div className="ColorsList">
                        {state.colorList.map((color: string) => (
                            <div
                                onClick={() => onColorPick(color)}
                                className={`ColorContainer`} style={{backgroundColor: color}}>
                                {color === state.selected &&
                                    <SVG  height={'0.85rem'} width={'0.85rem'} type={'check-mark'} />
                                }
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
};

export default ColorSelect;