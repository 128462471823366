import moment from "moment/moment";
export const DateFormat = 'DD.MM.YYYY';
class DaysService {
  public calcDays(dt: string) {
    const makeArray = (i: number) => {
      let array = [];
      for (let j = 1; j <= i; j ++){
        array.push(j)
      }
      return array;
    }
    const makeMonthFromArray = (array: number[], month: string, year: string) => {
      return array.map(day => `${day <= 9 ? '0' + day : day}.${month}.${year}`)
    }
    const format = DateFormat;
    const nextLen = moment(dt, format).add(1, 'month').daysInMonth();
    const currentLen = moment(dt, format).daysInMonth();
    const pastLen = moment(dt, format).subtract(1, 'month').daysInMonth();
    const pastMonth = makeMonthFromArray(makeArray(pastLen), moment(dt,format).subtract(1, 'month').format('MM'), moment(dt, format).subtract(1, 'month').format('YYYY'));
    const currentMonth = makeMonthFromArray(makeArray(currentLen), moment(dt,format).format('MM'), moment(dt, format).format('YYYY'));
    const nextMonth = makeMonthFromArray(makeArray(nextLen), moment(dt,format).add(1, 'month').format('MM'), moment(dt, format).add(1, 'month').format('YYYY'));
    const firstDay = moment(dt, format).startOf('month').format(format);
    const firstDayIndex = moment(firstDay, format).day() === 0 ? 7 : moment(firstDay, format).day();

    let daysArray: {disabled: boolean, dt: string}[] = [];
    if (firstDayIndex !== 1){
      const diff = Math.abs(1 - firstDayIndex);
      const pastIndexes = makeArray(diff);
      pastIndexes.map(ind => daysArray.unshift({disabled: true, dt: pastMonth[pastMonth.length - ind]}));
    }
    const currentIndexes = makeArray(currentLen);
    currentIndexes.map(ind => daysArray.push({disabled: false, dt: currentMonth[ind - 1]}))
    const nextIndexes = makeArray(42 - daysArray.length);
    nextIndexes.map(ind => daysArray.push({disabled: true, dt: nextMonth[ind - 1]}));
    return daysArray;
  }

  public getYearsDays = (year) => {
    const months = ['01','02','03','04','05','06','07','08','09',10,11,12];
    let days = [];
    months.map(monthIndex => {
      const daysInCurrentMonth = moment(`01.${monthIndex}.${year}`, DateFormat).daysInMonth();
      for (let i = 1; i <= daysInCurrentMonth; i++){
        days.push(`${i < 10 ? '0' + i : i}.${monthIndex}.${year}`)
      }
    })
    return days;
  }

  public increaseMonth(dt: any): {dt: string, daysArray: any[]}{
    const newDt = moment(dt.dt, DateFormat).add(1, 'month').format(DateFormat);
    const daysArray = this.calcDays(newDt);
    return {dt: newDt, daysArray}
  }
  public decreaseMonth(dt: any): {dt: string, daysArray: any[]}{
    const newDt = moment(dt.dt, DateFormat).subtract(1, 'month').format(DateFormat);
    const daysArray = this.calcDays(newDt);
    return {dt: newDt, daysArray}
  }

}
export default new DaysService()
