import $ from "jquery";

export const ThemeService =  (function ThemeService() {
  let _instance: any;
  let subscriptions: any = [];
  function getTheme(){
    return localStorage.getItem('theme') || 'light';
  }
  function subscribeUpdates(callback: (theme: string) => void){
    subscriptions.push(callback);
  }

  function setTheme(theme: string){
    theme === 'light'
      ? $('#root').removeClass('dark')
      : $('#root').addClass('dark')

    if (subscriptions.length > 0) subscriptions.map((callback: any) => callback(theme));
  }

  function toggleTheme(){
    const currentThemeDark = $('#root').hasClass('dark');
    currentThemeDark
      ? $('#root').removeClass('dark')
      : $('#root').addClass('dark')
    if (subscriptions.length > 0) subscriptions.map((callback: any) => callback(currentThemeDark ? 'light' : 'dark'));
    localStorage.setItem('theme', currentThemeDark ? 'light' : 'dark');
  }

  const createInstance = () => {
    return {
      getTheme,
      toggleTheme,
      subscribeUpdates,
      setTheme
    }
  }

  return {
    getInstance: function (){
      return _instance || (_instance = createInstance())
    }
  }
})();
