import {createSlice} from "@reduxjs/toolkit";

export interface IDetail {
  user_id: number,
  checked: boolean,
  detail: string,
  id: number
}

interface IPush {
  details: IDetail[]
}


export const PushService = createSlice({
  name: 'NotificationService',
  initialState: <IPush>{
    details: []
  },
  reducers: {
    setPush(state, action: {payload: IDetail}){
      state.details = state.details.concat([action.payload]);
    },
    removePush(state, action: {payload: IDetail}){
      state.details = state.details.filter((detail: IDetail) => detail.id !== action.payload.id)
    }
  }
})

export const {setPush, removePush} = PushService.actions;

