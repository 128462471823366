import React from 'react';
import './Navigation.scss';
import SVG from "../SVG/SVG";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {setDt} from "../../../service/Date";
import {DateFormat} from "../../../service/DaysService";
import moment from "moment";


const Navigation = () => {

  const dispatch = useDispatch();
  const dt = useSelector((state: RootState) => state.dt);

  function onClick(action){
    const unit: string = dt.mode.value;
    switch (action){
      case 'decrease':
        // @ts-ignore
        dispatch(setDt(moment(dt.dt, DateFormat).subtract(1, unit).format(DateFormat)));
        break;
      case 'increase':
        // @ts-ignore
        dispatch(setDt(moment(dt.dt, DateFormat).add(1, unit).format(DateFormat)));
    }
  }

  return (
    <div className={'NavigationWrapper'}>
      <div className="Arrow" onClick={() => onClick('decrease')}>
        <SVG type={'corner'} width={'1rem'} height={'1rem'} className={'Decrease'} />
      </div>
      <div className="Arrow" onClick={() => onClick('increase')}>
        <SVG type={'corner'} width={'1rem'} height={'1rem'} className={'Increase'} />
      </div>
    </div>
  );
};

export default Navigation;