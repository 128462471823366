import React, {useEffect, useState} from 'react';
import './Year.scss'
import daysService from "../../../../service/DaysService";
import {useDispatch, useSelector} from "react-redux";
import {setMode, setDt} from "../../../../service/Date";
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import SVG from "../../SVG/SVG";
import {cn} from "../../../../service/Calendar";
import moment from "moment";
import {RootState} from "../../../../store/store";
import $ from 'jquery';
import DaysService from "../../../../service/DaysService";

type week = Array<{ dt: string, disabled: boolean }>


const Year = (props) => {
  const {calendars, freqEvents, events} = props;
  const months = ['ЯНВАРЬ', 'ФЕВРАЛЬ', 'МАРТ', 'АПРЕЛЬ', 'МАЙ', 'ИЮНЬ', 'ИЮЛЬ', 'АВГУСТ', 'СЕНТЯБРЬ', 'ОКТЯБРЬ', 'НОЯБРЬ', 'ДЕКАБРЬ']
  const yearActive = props.selected.dt.split('.')[2]
  const dispatch = useDispatch();
  const holidays = useSelector((state: RootState) => state.holidays).days;

  const [state, setState] = useState<{ events: string[], evAr: any[] }>({
    events: [],
    evAr: [],
  })

  DaysService.getYearsDays('2023');

  useEffect(() => {
    setState({...state, evAr: events})
  }, [events])

  function choiceDay(dt: string) {
    dispatch(setMode('day'));
    dispatch(setDt(dt));
  }

  function setDay(dt: string) {
    dispatch(setDt(dt));
  }

  function choiceMonth(month: number) {
    const dt = props.selected.dt.split('.')
    const newDt = `01.${month < 10 ? '0' + month : month}.${dt[2]}`
    dispatch(setDt(newDt));
    dispatch(setMode('month'));
  }

  function setYear(year: number) {
    const dt = props.selected.dt.split('.')
    const newDt = `${dt[0]}.${dt[1]}.${year}`
    dispatch(setDt(newDt));
  }

  const getMonth = (monthCount: number) => {
    const monthDays = daysService.calcDays(`01.${monthCount < 10 ? '0' + monthCount : monthCount}.${yearActive}`);
    const getHolidays = (day) => {
      return holidays.includes(day.dt) || moment(day.dt, 'DD.MM.YYYY').day() == 0
        || moment(day.dt, 'DD.MM.YYYY').day() == 6
    }

    return (<>
      {monthDays.map(day => {
        const currentEvents = (events[day.dt] || []).concat((freqEvents[day.dt] || []));
        return (
          <span
            key={Math.random()}
            onDoubleClick={() => day.disabled ? null : choiceDay(day.dt)}
            onClick={() => day.disabled ? null : setDay(day.dt)}
            className={cn('MonthDay', {
              Disabled: day.disabled,
              Active: day.dt === moment().format('DD.MM.YYYY')
            })}>
            {!!currentEvents.length ?
              <>
                <div className="Events">
                  {currentEvents.map((ev, i) => (
                    <div className="Event" style={{
                      backgroundColor: ev.hasOwnProperty('calendar') ? ev.color : ev.tag.color,
                      width: (1 / currentEvents.length) * 100 - 1 + '%',
                      left: (1 / currentEvents.length) * 100 * i + '%',
                    }}></div>
                  ))}
                </div>
                <Tooltip title={<div style={{display: 'flex', flexDirection: "column"}}>
                  {currentEvents.map((ev: any) => (
                    <span>{ev.title || '(Без названия)'}</span>
                  ))}
                </div>} placement={'right-start'}>
                  <span className={cn('Event', {
                    notEmpty: true,
                    Holidays: getHolidays(day)
                  })}>{day.dt.split('.')[0]}</span>
                </Tooltip>
              </>
              : <span className={cn('Event', {Holidays: getHolidays(day)})}>{day.dt.split('.')[0]}</span>
            }
          </span>)
      })}
    </>)
  }

  return (<div className="Year">
    <div className="YearSelect">
      <div className="DecreaseYear YearNavigate" onClick={() => setYear(Number(yearActive) - 1)}>
        <SVG type={'corner'}
             width={'1.25rem'}
             height={'1.25rem'} />
      </div>
      {yearActive}
      <div className="IncreaseYear YearNavigate" onClick={() => setYear(Number(yearActive) + 1)}>
        <SVG type={'corner'}
             width={'1.25rem'}
             height={'1.25rem'} />
      </div>
    </div>
    <div className="YearContainer">
      {months.map((month, ind) => {
        return (<div key={Math.random()} className={'YearBox'}>
          <div className={'MonthName'}>
            <span onClick={() => choiceMonth(ind + 1)} className={'MonthNameItem'}>{month}</span>
          </div>
          <div className={'MonthWeekDays'}>
            <span className={'MonthDay'}>ПН</span>
            <span className={'MonthDay'}>ВТ</span>
            <span className={'MonthDay'}>СР</span>
            <span className={'MonthDay'}>ЧТ</span>
            <span className={'MonthDay'}>ПТ</span>
            <span className={'MonthDay'}>СБ</span>
            <span className={'MonthDay'}>ВС</span>
          </div>
          <div className={'MonthDays'}>{getMonth(ind + 1)}</div>
        </div>)
      })}
    </div>
  </div>)
}

export default Year;
