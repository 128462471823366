import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {AuthenticationService} from "../../../../service/LoginService";
import {setCalendars} from "../../../../service/SubscribedService";
import './Panels.scss';
import LabeledInput from "../../LabeledInput/LabeledInput";
import ColorPalette from "../../LeftPanel/Filter/ColorPalette";
export const ImportPanel = (props: any) => {
  const [state, setState] = useState<{
    link: string,
    title: string,
    error: string,
    color: string,
    isLoading: boolean
  }>({
    link: '',
    title: '',
    error: '',
    color: '#828fce',
    isLoading: false
  });
  const dispatch = useDispatch();
  const AuthService = AuthenticationService.getInstance();
  const {toggle} = props;

  function importCal() {
    setState({...state, error: '', isLoading: true});
    AuthService.makeRequest('/api/import/', 'POST', {
      title: state.title,
      link: state.link,
      color: state.color
    }).then((res: any) => {
      dispatch(setCalendars(res.data.map((cal: any) => {return {...cal, active: true}})));
      toggle();
    }).catch((e: any) => {
      setState({...state, error: e.response.data.detail, isLoading: false})
    })
  }

  return (
      <div className="Wrapper">
        <div className="Title">Новая подписка</div>
        <LabeledInput value={state.title} title={'Название'} onChange={(value) => setState({...state, title: String(value)})} />
        <LabeledInput value={state.link} title={'Ссылка'} onChange={(value) => setState({...state, link: String(value)})} />
        <label className={'label'} htmlFor="">Цвет</label>
        <ColorPalette calendar={true} onChange={(color) => setState({...state, color})} currentColor={state.color} />
        {state.error
          ? <div className={'Error'}>{state.error}</div>
          : ''
        }
        <div className="Footer">
          {state.isLoading
            ? <div className={'loader'}></div>
            : <div className={'Button'} onClick={() => importCal()}>Подписаться</div>
          }
        </div>
      </div>
  );
};