import React from 'react';
import SVG from "../../SVG/SVG";
import './ColorPalette.scss';

const ColorPalette = ({onChange, currentColor, calendar}
                        :{onChange: (color: string) => void, currentColor: string, calendar?: boolean}) => {
  const colorList = [
    calendar ? '#828fce' : '#9797C4', '#66A3FF', '#64CAF6', '#92E103', '#E2D704', '#FF88F3',
    '#8B2A81', '#0F6CF9', '#97BCC4', '#00AC7D', '#F0BA69', '#F80077',
    '#9400B9', '#034CBA', '#039ECF', '#2EAB50', '#E59926', '#B90059',
    '#30003B', '#305897', '#00ABAB', '#016D50', '#F87700', '#F80F00'
  ];

  return (
    <div className="ColorPalette">
      {colorList.map(color =>
        <div key={color} onClick={() => onChange(color)} className={'ColorBox'} style={{backgroundColor: color}}>
          {currentColor === color &&
              <SVG type={'check-mark'} width={'0.8rem'} height={'0.8rem'} />
          }
        </div>
      )}
    </div>
  );
};

export default ColorPalette;