import {createSlice} from "@reduxjs/toolkit";

interface INotification {
  id: number,
  created: string,
  detail: string,
  checked: {
    user_id: number,
    checked: boolean,
    detail: string
  }[]
}


export const NotificationService = createSlice({
  name: 'NotificationService',
  initialState: <{eventsStore: INotification[]}>{
    eventsStore: []
  },
  reducers: {
    setNotification(state, action){
      state.eventsStore = action.payload;
    }
  }
})

export const {setNotification} = NotificationService.actions;

