import React, {useEffect, useState} from 'react';
import './InputWithSearch.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";

const InputWithSearch = ({onChange, value, children}) => {
    const [state, setState] = useState({
        isListVisible: false,
        query: '',
        list: [],
        filteredList: []
    });

    const tags = useSelector((state: RootState) => state.tags).tags;

    useEffect(() => {
        const tagsList = tags.map(tag => tag.title)
        setState({...state, list: tagsList, query: value || ''});
    }, [tags, value])

    function inputChange(e){
        const value = e.target.value;
        setState({...state,
            query: value,
            isListVisible: value.length > 0,
            filteredList: state.list.filter(el => el.toLowerCase().match(value.toLowerCase()))
        });
        onChange(value);
    }

    function select(value){
        setState({...state, query: value, isListVisible: false});
        onChange(value);
    }

    return (
        <div className={'InputWithSearch'} onKeyPress={(e) => {
            if (e.key === "Enter") setState({...state, query: state.query, isListVisible: false})
        }}>
            {state.isListVisible &&
                <div className={'List'}>
                    {state.filteredList.length > 0 ? state.filteredList.map(el => (
                        <div onClick={() => select(el)}>{el}</div>
                        ))
                        : <div onClick={() => setState({...state, isListVisible: false})}>Ничего не найдено</div>
                    }
                </div>
            }
            <input style={{border: 'none'}} onChange={inputChange} value={state.query} type="text"/>
            {children}
        </div>
    );
};

export default InputWithSearch;