import axios from "axios";
import {createSlice} from "@reduxjs/toolkit";


export interface IState {
  logined: boolean,
  access_token?: string,
  refresh_token?: string,
  loading?: boolean,
  user?: {
    id: number,
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    groups: [],
  },
  user_info: any
}

export const AuthService = createSlice({
  name: 'AuthService',
  initialState: <IState>{
    logined: false,
    loading: true,
    user: {},
    access_token: '',
    refresh_token: '',
    user_info: {}
  },
  reducers: {
    set(state, action){
      const keys = Array.from(Object.keys(action.payload))
      keys.map(key => state[key as keyof Object] = action.payload[key])
    }
  }
})

export const {set} = AuthService.actions;

interface IAuthenticationService{
  check: () => any,
  login: (username: string, password: string) => any,
  getModel: () => IState,
  dispose: () => void
}

export const AuthenticationService = (function(): any {
  let state = {
    logined: false,
    loading: true,
    user: {},
    access_token: '',
    refresh_token: '',
    usersList: []
  }
  let _instance: any;

  let subscribes: any = [];

  function setState(options: any){
    state = {...state, ...options}
    if (subscribes.length > 0){
      subscribes.map((callback: any) => callback(state))
    }
  }

  function getRoute(method: string) {
    const api_route = (method: string) => {
      switch (method.toLowerCase()){
        case 'check':
          return 'api/check/';
        case 'login':
          return 'api/login/';
        case 'refresh':
          return 'api/auth/jwt/refresh/';
        case 'me':
          return 'api/me/';
        case 'users':
          return 'api/users/'
      }
    }
    return String(api_route(method));
  }


  const makeRequest = async (url: string, method = 'GET', payload?: {}) => {
    let model = getModel();
    if (!model.logined || model.loading) return;
    payload = {...payload, access_token: model.access_token, refresh_token: model.refresh_token}
    switch(method){
      case 'POST':
        return await axios.post(url, payload, {headers: {
            'Authorization': 'Bearer ' + model?.access_token
          }});
      case 'PUT':
        return await axios.put(url, payload, {headers: {
            'Authorization': 'Bearer ' + model?.access_token
          }});
      case 'PATCH':
        return await axios.patch(url, payload, {headers: {
            'Authorization': 'Bearer ' + model?.access_token
          }});
      case 'DELETE':
        return await axios.delete(url, {headers: {
            'Authorization': 'Bearer ' + model?.access_token
          }});
      default:
        return await axios.get(url, {headers: {
            'Authorization': 'Bearer ' + model?.access_token
          }});
    }
  }

  const getUsers = async () => {
    if (state.usersList.length === 0) {
      return makeRequest(getRoute('users')).then(res => {
        setState({usersList: res.data});
        return res;
      });
    }
    else return {data: state.usersList};
  }

  const check = async () => {
    const model = state;
    const token = model.access_token ? model.access_token : localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null;
    const refresh = model.refresh_token ? model.refresh_token : localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null;
    if (!model || !token) return false;
    const response = await axios.post(getRoute('check') , {
      access: token, refresh
    }).then((res: any) => res).catch(e => e.response);
    const options = {logined: response.status === 200, access_token: response.data.access,
      refresh_token: response.data.refresh, loading: false, user: response.data.user, user_info: response.data.info}
    setState(options);
    return options;
  }

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setState({access_token: '', refresh_token: '', logined: false, loading: false, user: {
      username: '',
        groups: [],
        id: 0,
        first_name: '',
        last_name: '',
        email: ''
      }})
  }

  const login = async (username: string, password: string) => {
    const response = await axios.post(getRoute('login'), {
      username, password
    }).then((res: any) => res).catch(e => e.response)
    if (response.status === 200){
      const [access_token, refresh_token, user, user_info]
          =
          [response.data.access, response.data.refresh, response.data.user, response.data.info];
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      const options = {...state, access_token, user, refresh_token, user_info, logined: true, loading: false};
      setState(options);
      return options;
    }
    else {
      return {status: 'error', error_message: response.data.detail}
    }
  }

  const getModel = () => {
    return state;
  }

  function subscribeUpdates(callback: any){
    const strSubscribes = subscribes.map((func: any) => `${func}`)
    if (!strSubscribes.includes(`${callback}`)) subscribes.push(callback);
  }

  const createInstance = () => {
    return {
      state,
      getModel,
      login,
      setState,
      makeRequest,
      check,
      logout,
      getUsers,
      getRoute,
      subscribeUpdates
    }
  }

  return {
    getInstance: function (){
      return _instance || (_instance = createInstance())
    }
  }
})();
