import React, {useEffect, useState} from 'react';
import './FilterUser.scss';
import {AuthenticationService} from "../../../../service/LoginService";
import Svg from "../../SVG/SVG";
import {useDispatch, useSelector} from "react-redux";
import {clearCurrentUser, setCurrentUser} from "../../../../service/CurrentUserService";
import SVG from "../../SVG/SVG";
import Search from "./Search";

interface IUser {
  username: string,
  email: string,
  id: number,
  active?: boolean,
  first_name: string,
  last_name: string
}

const FilterUser = () => {
  const [state, setState] = useState<{
    usersList: IUser[],
    expanded: boolean,
    currentUser?: IUser,
    query: string,
    tmpUsers: IUser[],
    favoriteList: number[]
  }>({
    usersList: [],
    expanded: true,
    query: '',
    tmpUsers: [],
    favoriteList: []
  });

  const AuthService = AuthenticationService.getInstance();
  const AuthModel = AuthService.getModel()
  const currentUser = AuthModel.user;
  const currentUserInfo = AuthModel.user_info;
  const dispatch = useDispatch();

  const mergeUsers = (array, favoriteList) => {
    const userSort = (a, b) => {
      const a_name = a.first_name.length ? a.first_name : a.username;
      const b_name = b.first_name.length ? b.first_name : b.username;
      return a_name.localeCompare(b_name);
    }

    const allUsersList = array.filter(user => user.id !== currentUser.id);
    const favoriteUsersId = favoriteList;
    const notFavoriteUsers = allUsersList.filter(user => !favoriteUsersId.includes(user.id)).sort(userSort);
    const favoriteUsersList = favoriteUsersId.map(id => allUsersList.find(user => user.id == id)).sort(userSort);
    const formattedUsers = [...favoriteUsersList, ...notFavoriteUsers];
    setState({...state, usersList: formattedUsers, tmpUsers: formattedUsers, favoriteList: favoriteUsersId})
  }

  useEffect(() => {
    AuthService.getUsers().then((res: any) => {
      mergeUsers(res.data.filter((us: any) => us.id !== currentUser.id && us.username && us.first_name && us.last_name),
          JSON.parse(currentUserInfo.favorite_list || '[]'));
    })
  }, [])

  function toggleUser(user: any){
    if (state.currentUser && state.currentUser.id === user.id){
      setState({...state, currentUser: undefined, usersList: state.usersList.map((user: IUser) => {return{...user, active: false}})})
      dispatch(clearCurrentUser())
    }
    else {
      setState({...state, usersList: state.usersList.map((el: any) => {
          if (el.id === user.id) return {...user, active: true};
          else return {...el, active: false}
        }), currentUser: user})
      dispatch(setCurrentUser(user))
    }
  }

  useEffect(() => {
    if (state.query.length === 0) {
      setState({...state, usersList: state.tmpUsers});
      return;
    }
    const searchedUsers = state.usersList.filter((user) => {
      const name = (user.first_name.length && user.last_name.length) ? user.first_name + ' ' + user.last_name : user.username;
      return name.toLowerCase().match(state.query.toLowerCase()) || user.username.toLowerCase().match(state.query.toLowerCase());
    })
    setState({...state, usersList: searchedUsers})
  }, [state.query])

  function toggleFavorite(user){
    let list;
    if (state.favoriteList.includes(user.id)) list = state.favoriteList.filter(id => id !== user.id);
    else list = [...state.favoriteList, user.id];
    AuthService.makeRequest('/api/user_info/', 'POST', {
      action: 'post',
      favorite_list: list
    }).then(res => {
      setState({...state, favoriteList: JSON.parse(res.data.favorite_list)});
      AuthService.setState({user_info: {...AuthService.getModel().user, favorite_list: res.data.favorite_list} })
      mergeUsers(state.usersList, JSON.parse(res.data.favorite_list));
    });
  }

  return (
    <div className={`FilterWrapper${state.expanded ? ' active' : ''}`}>
      <div className={`FilterInput`} onClick={() => setState({...state, expanded: !state.expanded})}>
        <div className={'Corner'}>
          <Svg height={'0.75rem'} width={'0.75rem'} type={'corner'} />
        </div>
        <span className={'FilterTitle'}>Расписание коллег</span>
      </div>
      {state.expanded &&
        <>
          <Search onChange={(e) => setState({...state, query: e.target.value})} />
          <div className="FilterBody">
            {state.usersList.map((user: any) => (
              <div key={Math.random()} className={`UserRadioBox${state.currentUser?.id === user.id ? ' active' : ''}`} >
                <SVG onClick={() => toggleFavorite(user)} type={`${state.favoriteList.includes(user.id) ? 'star-select' : 'star-unselect'}`}
                     width={'1.15rem'} height={'1.15rem'} />
                <span onClick={() => toggleUser(user)}>
                  {(user.first_name.length && user.last_name.length) ? user.first_name + ' ' + user.last_name : user.username}
                </span>
              </div>
            ))
            }
          </div>
        </>
      }
    </div>
  );
};

export default FilterUser;
