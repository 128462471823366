import React, {useEffect, useState} from 'react';
import './Week.scss'
import {setDt, setMode} from "../../../../service/Date";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {setForm} from "../../../../service/EditFormService";
import EventBox from "../EventBox/EventBox";
import ModalInformation from "../Month/ModalInformation";
import {RootState} from "../../../../store/store";
import {cn, daysWeek, getEventsColumn, hours, showDetails} from "../../../../service/Calendar";
import {DateFormat} from "../../../../service/DaysService";

type week = Array<{ dt: string, disabled: boolean }>
type propsWeek = {
  week: week
  selected: { dt: string, daysStore: Array<{ dt: string, disabled: boolean }>, mode: week },
  events: any,
  calendars: any,
  freqEvents: any
}

const Week = (props: propsWeek) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    events: [],
    currentEvent: undefined,
    isModalVisible: false,
    startEvent: '',
    endEvent: '',
    selected: {dt: props.selected.dt},
    calendarEvents: {}
  })
  const [coord, setCoord] = useState({x: 0, y: 0});
  const form = useSelector((state: RootState) => state.editForm);

  useEffect(() => {
    setState({...state, currentEvent: undefined})
  }, [props.events, props.selected, props.calendars])


  function closeModal() {
    setState({...state, isModalVisible: false, startEvent: '', endEvent: ''})
  }

  function setDay(dt: string) {
    dispatch(setDt(dt));
  }

  function onClickEvent(event: any) {
    if (!event.previewOnly) setState({...state, currentEvent: event, isModalVisible: true});
  }

  function choiceDay(dt: string) {
    dispatch(setMode('day'));
    dispatch(setDt(dt));
  }


  function newEvent(hour, index, day) {
    const options = {
      ...state,
      currentEvent: undefined,
      startEvent: hour + ':00',
      endEvent: hour + 1 + ':00',
      selected: {dt: props.week[index].dt}
    }

    setDay(day);

    dispatch(setForm({
      startDt: options.selected.dt + ' ' + options.startEvent,
      endDt: options.selected.dt + ' ' + options.endEvent,
      currentDt: options.selected.dt,
      currentEvent: null,
      visible: true,
      mode: 'new'
    }))
    setState(options);
  }

  function getEventsForDay(dt){
    return (props.events[dt] ? [...props.events[dt]] : [])
      .concat(props.freqEvents[dt])
      .concat(props.calendars[dt]);
  }

  return (
    <>
      {state.isModalVisible ?
        <ModalInformation close={closeModal} coord={coord} event={state.currentEvent}/>
        : <></>
      }
      <div className={'WeekBox'}>
        <div className="WeekName">
          <div className="WeekColumn hours">
            <div className={'WeekDay'}></div>
            {hours.map(hour => (
              <div className={'WeekDayBox label'} key={hour}><span className={'Hour'}>{`${hour}:00`}</span></div>
            ))}
          </div>
          {daysWeek.map((day: string, index: number) => (
              <div key={day} className={'WeekColumn'}>
                <div onDoubleClick={() => choiceDay(props.week[index].dt)}
                     onClick={() => setDay(props.week[index].dt)}
                     className={cn('WeekDay', {
                       active: props.week[index].dt === props.selected.dt,
                       current: moment().format('DD.MM.YYYY') === props.week[index].dt,
                       weekend: index === 6 || index === 5
                     })}
                >
                  <div className={'WeekDayName'}>{day}</div>
                  <div className={'WeekDayNumber'}>
                    {props.week[index].dt
                      .replace('.' + moment().format('YYYY'), '')}
                  </div>
                </div>
                {hours.map(hour => (
                  <div className={'WeekDayBox'} key={Math.random()}>
                    <div className={'Background'}
                         onClick={() => newEvent(hour, index, props.week[index].dt)}></div>
                    {getEventsColumn(hour, props.week[index].dt, getEventsForDay(props.week[index].dt), form,
                      (ev, i, currentHourEvents) =>
                        <div
                          onClick={(e) => onClickEvent(ev)}
                          className={`GridTimeColumn`}
                          style={{
                            height: ev.height + ev.height / 100 * 6.5 + '%',
                            position: 'absolute',
                            width: 95 / currentHourEvents.length - 1 + '%',
                            left: (i * 95 / currentHourEvents.length) + '%',
                            top: ev.top
                          }}
                          key={Math.random()}>
                          <EventBox className={'Week'}
                                    onClick={(e) => showDetails(e, ev, state.currentEvent, (visible, x, y) => {
                                      setState({...state, isModalVisible: visible, currentEvent: visible ? ev : null});
                                      setCoord({...state, x, y});
                                    })}
                                    formEvent={ev.previewOnly}
                                    event={ev}
                          />
                        </div>
                    )}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
};

export default Week;