import React, {useEffect, useState} from 'react';
import {AuthenticationService} from "../../../service/LoginService";
import '../DropDownMode/DropDown.scss'
import {ThemeService} from "../../../service/ThemeService";
import SVG from '../SVG/SVG';
import {FormatUsername} from "../EditForm/Members";
import $ from "jquery";

const DropDownUser = (props: {
  username: string, showExport: () => void, showLink: () => void, logout: () => void,
  showImport: () => void, showSettings: () => void, show: () => void, visible: boolean,
  otherOverlay: boolean
}) => {
  const [state, setState] = useState<{ isVisible: boolean, theme: any }>({
    isVisible: false, theme: 'light'
  })
  const {username, showExport, showLink, logout, showImport, showSettings, show, visible, otherOverlay} = props;

  const AuthService = AuthenticationService.getInstance();

  function logoutFunc() {
    AuthService.logout();
    logout();
  }

  const themeService = ThemeService.getInstance()

  useEffect(() => {
    setState({...state, theme: themeService.getTheme()});
  }, [])

  function toggleTheme() {
    themeService.toggleTheme();
    setState({...state, theme: themeService.getTheme()})
  }


  return (
    <div className={'DropDown'}>
      <div style={{minWidth: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.5rem'}}
           onClick={show}>
        <span>{FormatUsername(username)}</span>
        <SVG className={'UserIcon'} type={'user'} width={'2.85rem'} height={'2.75rem'}/>
      </div>
      {visible &&
          <>
            <div className="SelectModalOVerlay" onClick={show}></div>
            <div className="Dropdown__select" style={{zIndex: otherOverlay ? 5 : 10}}>
                <div className={'DropDown__select-item'} onClick={showExport}><div>Экспортировать</div></div>
                <div className={'DropDown__select-item'} onClick={showImport}><span>Синхронизировать</span></div>
                <div className={'DropDown__select-item'} onClick={showLink}><span>Поделиться</span></div>
                <div className={'DropDown__select-item'} onClick={toggleTheme}>
                    <span>{$('#root').hasClass('dark') ? 'Светлая тема' : 'Тёмная тема'}</span>
                </div>
                <div className={'DropDown__select-item'} onClick={showSettings}><span>Настройки</span></div>
                <div className={'DropDown__select-item red'} onClick={logoutFunc}><span>Выйти</span></div>
            </div>
          </>

      }
    </div>
  );
};

export default DropDownUser;
