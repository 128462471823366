import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";


export const DateService = createSlice({
  name: 'DateService',
  initialState: <{ dt: string, mode: {value: string, title: string}, daysStore: {dt: string, disabled: boolean}[] }>{
    dt: moment().format('DD.MM.YYYY'),
    daysStore: [],
    mode: {value: '', title: ''}
  },
  reducers: {
    setDt(state, action){
      state.dt = action.payload;
    },
    setDaysStore(state, action){
      state.daysStore = action.payload;
    },
    setMode(state, action){
      let mode;
      switch (action.payload){
        case 'day':
          mode = {value: 'day', title: 'День'};
          break;
        case 'week':
          mode = {value: 'week', title: 'Неделя'};
          break;
        case 'month':
          mode = {value: 'month', title: 'Месяц'};
          break;
        case 'year':
          mode = {value: 'year', title: 'Год'};
          break;
        default:
          mode = {value: 'month', title: 'Месяц'};
          break;
      }
      state.mode = mode;
    }
  }
})

export const {setDt, setDaysStore, setMode} = DateService.actions;

