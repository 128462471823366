import {createSlice} from "@reduxjs/toolkit";

export const HolidaysService = createSlice({
  name: 'HolidaysService',
  initialState: {
    days: []
  },
  reducers: {
    setHolidays: (state, action) => {
      state.days = action.payload
    }
  }
})

export const {setHolidays} = HolidaysService.actions;

