import React, {useEffect, useState} from 'react';
import './EventBox.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";

const EventBox = ({event, onClick, className, formEvent}:
                    { event?: any, onClick?: (value: any) => void, className?: string, formEvent?: boolean, }) => {

  const form = useSelector((state: RootState) => state.editForm.model);
  const formMode = useSelector((state: RootState) => state.editForm.mode);
  if (form && formEvent !== undefined && formEvent && formMode !== 'edit') event = {...form, approved: true, title: 'Новое событие'};

  let [color, symbol, fontColor] = [event?.color, '!'.repeat(event?.important), 'white']
  if (!event?.approved && !event?.calendar) {
    [color, symbol, fontColor] = ['#f9e7cd', '?', '#828282']
  }

  function onClickBox(e){
    if (formEvent) return;
    else onClick(e)
  }

  return (
    <div key={Math.random()}
         className={`EventBox ${className || ''}`}
         style={{backgroundColor: event?.approved ? !!event.tag ? event?.tag?.color : event.tag_color : color}}
         onClick={onClickBox}
    >
      <div className={'ImportantMark'} style={{color: fontColor}}>{symbol}</div>
      <span className={'EventTitle'} style={{color: fontColor}}>{event?.title || '(Без названия)'}</span>
    </div>
  );
};

export default EventBox;