import React, {useEffect, useState} from 'react';
import './HeaderPanel.scss';
import {useDispatch, useSelector} from "react-redux";
import DropDownUser from "../DropDownUser/DropDownUser";
import {IMode} from "../../FunctionalPanel/FunctionalPanel";
import {AuthenticationService} from "../../../service/LoginService";
import moment from "moment";
import {
  Badge,
  Box,
  IconButton
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Slide from "../DropDownMode/DropDown";
import {RootState} from "../../../store/store";
import {setDt, setMode} from "../../../service/Date";
import Logo from "../Logo/Logo";
import Navigation from "./Navigation";
import {ImportPanel} from "./Panels/ImportPanel";
import {SubLinkPanel} from "./Panels/SubLinkPanel";
import {ExportPanel} from "./Panels/ExportPanel";
import {SettingsPanel} from "./Panels/SettingsPanel";
import {INotification, NotificationPanel} from "./Panels/NotificationPanel";
import {cn} from "../../../service/Calendar";

export function formatDate(dt: string) {
  const dtArray = dt.split('T');
  if (dtArray.length === 1) return dtArray[0];
  else return moment(dtArray[0], 'YYYY-MM-DD').format('DD.MM.YYYY') + ' ' + dtArray[1].slice(0, 5);
}

const HeaderPanel = (props: { change: (mode: IMode) => void, logout: () => void }) => {
  const [state, setState] = useState<{
    auditEvents: INotification[],
    isNotificationVisible: boolean,
    exportPanelVisible: boolean,
    subLinkVisible: boolean,
    importPanelVisible: boolean,
    otherUser: boolean,
    settingsPanelVisible: boolean,
    isUserDropDownVisible: boolean
  }>(
    {
      auditEvents: [],
      isNotificationVisible: false,
      exportPanelVisible: false,
      subLinkVisible: false,
      importPanelVisible: false,
      otherUser: false,
      settingsPanelVisible: false,
      isUserDropDownVisible: false
    });
  const notifications = useSelector((state: { notifications: any }) => state.notifications).eventsStore;
  const currentUser = useSelector((state: RootState) => state.currentUser).user;
  const mode = useSelector((state: RootState) => state.dt.mode);
  const dispatch = useDispatch();

  const {change, logout} = props;
  const modes = [{value: 'day', title: 'День'}, {value: 'week', title: 'Неделя'}, {
    value: 'month',
    title: 'Месяц'
  }, {value: 'year', title: 'Год'}];

  function onChange(mode: IMode) {
    change(mode)
  }

  function onTodayClick(){
    dispatch(setMode('day'));
    dispatch(setDt(moment().format('DD.MM.YYYY')));
  }

  const AuthService = AuthenticationService.getInstance();
  const user = AuthService.getModel().user;
  const setAudits = (audits: INotification[]) => setState({...state, auditEvents: audits})
  const toggleExportPanel = () => setState({...state, exportPanelVisible: !state.exportPanelVisible});
  const toggleLinkPanel = () => setState({...state, subLinkVisible: !state.subLinkVisible});
  const toggleSettingsPanel = () => setState({...state, settingsPanelVisible: !state.settingsPanelVisible});
  const toggleImportPanel = () => setState({...state, importPanelVisible: !state.importPanelVisible});
  const toggleUserDropDown = () => setState({...state, isUserDropDownVisible: !state.isUserDropDownVisible});

  useEffect(() => {
    setState({...state, otherUser: currentUser === undefined ? false : currentUser?.id !== user?.id});
  }, [currentUser])

  useEffect(() => {
    setState({...state, auditEvents: notifications})
  }, [notifications])

  function closeAllModal(){
    setState({
      ...state,
      isNotificationVisible: false,
      settingsPanelVisible: false,
      importPanelVisible: false,
      exportPanelVisible: false,
      subLinkVisible: false,
    })
  }

  return (
    <div className={'HeaderPanel__Wrapper'}>
      <div className={'HeaderLogo'}>
        <Logo />
      </div>
      <div className={'HeaderTitle'}>
        <div className="TodayButton" onClick={() => onTodayClick()}>
          Сегодня
        </div>
        <div className="Navigation">
          <Navigation />
        </div>
        <div className={'DropDown__Mode'}>
          <Slide change={onChange} currentMode={mode} availableModes={modes}/>
        </div>
      </div>
      <div className={'HeaderDetail'}>
        {state.otherUser && <div>Вы просматриваете расписание пользователя {currentUser?.first_name + ' ' + currentUser?.last_name}</div> }
      </div>
      <div className={'HeaderFunctions'}>
        <div className={'CurrentUser'}>
          <DropDownUser
            visible={state.isUserDropDownVisible}
            show={toggleUserDropDown}
            logout={logout}
            showExport={toggleExportPanel}
            showImport={toggleImportPanel}
            showLink={toggleLinkPanel}
            showSettings={toggleSettingsPanel}
            otherOverlay={state.importPanelVisible
              || state.subLinkVisible
              || state.exportPanelVisible
              || state.settingsPanelVisible
              || state.isNotificationVisible}
            username={user.username}
          />
        </div>

        {state.importPanelVisible
          || state.subLinkVisible
          || state.exportPanelVisible
          || state.settingsPanelVisible
          || state.isNotificationVisible
          ? <div
            className={cn("Overlay", {transparent: state.isNotificationVisible})}
            onClick={closeAllModal}></div> : ''
        }
        {state.importPanelVisible && <ImportPanel toggle={toggleImportPanel}/>}
        {state.subLinkVisible && <SubLinkPanel toggle={toggleLinkPanel}/>}
        {state.exportPanelVisible && <ExportPanel toggle={toggleExportPanel}/>}
        {state.settingsPanelVisible && <SettingsPanel modes={modes} toggle={toggleSettingsPanel}/>}

        <div style={{position: 'relative'}}>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <div className="NotificationIcon">
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={() => setState({...state, isNotificationVisible: !state.isNotificationVisible})}
            >
              <Badge
                badgeContent={notifications?.filter((event: INotification) => !event.checked.find(check => check.user_id === user?.id)?.checked
                && !!event.checked.find(check => check.user_id === user?.id)).length} color="error">
                  <NotificationsIcon />
              </Badge>
            </IconButton>
            </div>
          </Box>

          {state.isNotificationVisible &&
              <NotificationPanel
                  auditChange={setAudits}
                  toggleAudits={(value) => setState({...state, isNotificationVisible: value})}
                  events={state.auditEvents}
              />
          }
        </div>
      </div>
    </div>
  );
};

export default HeaderPanel;
