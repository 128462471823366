import React, {useEffect, useState} from 'react';
import './TagModal.scss';
import SVG from "../../SVG/SVG";
import {AuthenticationService} from "../../../../service/LoginService";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {setTags} from "../../../../service/TagsService";
import ColorPalette from "./ColorPalette";

const TagModal = ({close, coord, tag}) => {
  const [state, setState] = useState({
    tag: null
  })

  const AuthService = AuthenticationService.getInstance();
  const tags = useSelector((state: RootState) => state.tags.tags);
  const dispatch = useDispatch();

  useEffect(() => {
    setState({...state, tag})
  }, [tag])

  const colorList = [
    '#9797C4', '#66A3FF', '#64CAF6', '#92E103', '#E2D704', '#FF88F3',
    '#8B2A81', '#0F6CF9', '#97BCC4', '#00AC7D', '#F0BA69', '#F80077',
    '#9400B9', '#034CBA', '#039ECF', '#2EAB50', '#E59926', '#B90059',
    '#30003B', '#305897', '#00ABAB', '#016D50', '#F87700', '#F80F00'
  ];

  function updateTag(options){
    const newModel = {...state.tag, ...options};

    setState({...state, tag: newModel});

    AuthService.makeRequest(`/api/tags/${newModel.id}/`, 'PUT', {
      title: newModel.title,
      color: newModel.color
    }).then(res => {
      dispatch(setTags(tags.map(tag => {
        if (tag.id === res.data.id) return res.data;
        else return tag;
      })))
    })
  }

  function removeTag(){
    AuthService.makeRequest(`/api/tags/${tag.id}/`, 'DELETE').then(() => {
      dispatch(setTags(tags.filter(tg => tg.id !== tag.id)));
    }).then(() => close())
  }

  if (!state.tag) return <></>

  return (
    <>
      <div className={'TagModal__Wrapper__Overlay'} onClick={close}></div>
      <div className={'TagModal__Wrapper'} style={{top: coord.y, left: coord.x}}>
        <div className="closeBox">
          <SVG onClick={close} type={'X'} width={'0.75rem'} height={'0.75rem'} />
        </div>
        <span className={'label'}>Название</span>
        <input className={'titleInput'}
               onChange={(e) => updateTag({title: e.target.value})}
               value={state.tag.title} type="text"/>
        <div className="labelColor">Цвет тега</div>
        <ColorPalette onChange={(color) => updateTag({color})} currentColor={state.tag.color} />
        <div className="deleteButton" onClick={removeTag}>
          Удалить тег
        </div>
      </div>
    </>
  );
};

export default TagModal;