import React, {useState} from "react";
import {AuthenticationService} from "../../../../service/LoginService";
import moment from "moment/moment";
import LabeledInput from "../../LabeledInput/LabeledInput";


export const ExportPanel = (props: any) => {
  const {toggle} = props;
  const [state, setState] = useState<{
    dt_start: any,
    dt_end: any,
    error: string
  }>({
    dt_start: '',
    dt_end: '',
    error: ''
  });

  const AuthService = AuthenticationService.getInstance();

  async function exportData() {
    setState({...state, error: ''})
    const response = await AuthService.makeRequest(`/api/export/?dt=${state.dt_start}!${state.dt_end}`)
      .then((res: any) => res).catch((e: any) => e.response);
    if (response.status !== 200 || !response) {
      setState({...state, error: response?.statusText || 'Error'});
      return;
    }
    const blob = new Blob([response.data], {type: "text/plain"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "calendar.ics";
    link.href = url;
    link.click();
    toggle();
  }

  return (
      <div className="Wrapper">
        <div className="Title">Экспортировать</div>
        <LabeledInput
          title={'Начальная дата'}
          onChange={(newValue) => {
          setState({...state, dt_start: moment(newValue).format('YYYY-MM-DDTHH:mm')});
        }}
          value={state.dt_start} type={'datetime-local'}
          />
        <LabeledInput
          title={'Конечная дата'}
          onChange={(newValue) => {
          setState({...state, dt_end: moment(newValue).format('YYYY-MM-DDTHH:mm')});
        }}
          value={state.dt_end} type={'datetime-local'}
          />
        {state.error
          ? <div className={'Error'}>{state.error}</div>
          : ''
        }
        <div className="Footer">
          <div className="Button" onClick={exportData}>Экспортировать</div>
        </div>
      </div>
  );
};