import React, {useEffect, useState} from 'react';
import Login from "../Login/Login";
import {AuthenticationService} from "../../service/LoginService";
import moment from "moment";
import FunctionalPanel from "../FunctionalPanel/FunctionalPanel";
import 'moment/locale/ru'
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../../service/Notification";
import {setPush} from "../../service/Push";
import {setEvents} from "../../service/EventsService";
moment.locale('ru');
import '../../App.scss';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeService} from "../../service/ThemeService";
import {setCalendars} from "../../service/SubscribedService";
import {setTags} from "../../service/TagsService";
import {setHolidays} from "../../service/HolidaysService";
import {setMode} from "../../service/Date";

export const App = ()  => {
  const TService = ThemeService.getInstance();
  const [state, setState] = useState<{isLogined: boolean, theme: any, isLoading: boolean}>({
    isLogined: false,
    isLoading: false,
    theme: createTheme({
      palette: {
        mode: TService.getTheme(),
        background: {
          paper: TService.getTheme() === 'dark' ? '#273445' : '#FFFFFF',
        },
      },
    })
  })

  const AuthService = AuthenticationService.getInstance();
  const dispatch = useDispatch();
  const currenUser = useSelector((state: any) => state.currentUser).user;

  useEffect(() => {
    getAllData(currenUser?.id)
  }, [currenUser])

  function setTheme(theme: any){
    const currentTheme = createTheme({
      palette: {
        mode: theme,
        background: {
          paper: theme === 'dark' ? '#273445' : '#FFFFFF',
        },
      },
    });
    setState({...state, theme: currentTheme})
  }

  useEffect(() => {
    const theme = TService.getTheme();
    setTheme(theme);
    TService.setTheme(theme);
  }, [])


  TService.subscribeUpdates(onThemeChanged)

  function onThemeChanged(theme: string){
    setTheme(theme)
  }

  useEffect(() => {
    AuthService.check().then(completeAuth)
  }, [])

  function completeAuth(model: any){
    if (model.logined){
      const theme = model?.user_info?.prefer_theme;
      if (theme?.length !== 0) TService.setTheme(theme);
      setState({...state, isLoading: true});
      getAllData();
    }
  }

  function logout(){
    setState({...state, isLogined: false})
  }

  let canMakeRequest = true;
  function checkNotifications(){
    const logined = AuthService.getModel().logined;
    if (!canMakeRequest || !logined) return;
    const response = AuthService.makeRequest('/api/notifications_check/')
    canMakeRequest = false;
    response.then((res: any) => {
      const {current_user, new_event, audits} = res.data
      canMakeRequest = true;
      if (new_event){
        const lastElement = audits[audits.length - 1];
        const lastElementPush = {...lastElement.checked.find((event: any) => event.user_id == current_user), id: lastElement.id}
        if (lastElementPush.hasOwnProperty('detail')) dispatch(setPush(lastElementPush));
        dispatch(setNotification(audits))
      }
      checkNotifications()
    })
  }

  function getAllData(userId?: number){
    const events = AuthService.makeRequest(`/api/events/`, 'POST', {action: 'get', pk: userId});
    const audits = AuthService.makeRequest('/api/audits/');
    const tags = AuthService.makeRequest('/api/tags/');
    const calendars = AuthService.makeRequest('/api/calendars/', 'POST', {action: 'get'});
    const holidays = AuthService.makeRequest('/api/holidays/');

    Promise.all([events, audits, tags, calendars, holidays]).then(res => {
      const [ev, adts, tgs, clnd, hlds] = res;
      let completable = true;
      res.map(r => {
        if (!r) completable = false;
      });
      if (completable){
        dispatch(setEvents(ev.data));
        dispatch(setNotification(adts.data));
        dispatch(setTags(tgs.data));
        dispatch(setCalendars(clnd.data.map((cal: any) => {return {...cal, active: true}})));
        dispatch(setHolidays(hlds.data.map(h => moment(h.dt, 'YYYY-MM-DD').format('DD.MM.YYYY'))));
      }
      else {
        throw new Error('not completable')
      }
    }).then(() => {
      const mode = AuthService.getModel().user_info.prefer_mode;
      dispatch(setMode(mode?.value));
      setState({...state, isLogined: true});
    });
  }

  const onModelChange = (model) => {
    if (model.logined) checkNotifications();
  }

  useEffect(() => {
    AuthService.subscribeUpdates(onModelChange)
  }, [])

  return (
    <ThemeProvider theme={state.theme}>
      <CssBaseline />
      <div className={'Root'} style={{width: '100%', height: '100%', overflow: 'hidden'}}>
        {!state.isLogined ?
          <Login isLoading={state.isLoading} onThemeUpdate={setTheme} onComplete={completeAuth} />
          : AuthService.getModel().isLoading ? <></> :
          <FunctionalPanel logout={logout} />}
      </div>
    </ThemeProvider>
  );
};

export default App;
