import React from 'react';
import './Logo.scss';
import SVG from "../SVG/SVG";
import moment from "moment";
const Logo = () => {
    return (
        <div className={'Logo'}>
            <SVG type={'Logo'} width={'2.2rem'} height={'2.2rem'} />
            <span className={'LogoTitle'}>CALENDAR</span>
            <span className={'CurrentYear'}>{moment().format('YYYY')}</span>
        </div>
    );
};

export default Logo;