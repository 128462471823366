import React, {useEffect, useState} from "react";
import {AuthenticationService} from "../../../../service/LoginService";
import Slide from "../../DropDownMode/DropDown";


export const SettingsPanel = (props) => {
  const [state, setState] = useState({
    prefer_mode: {value: 'month', title: 'Месяц'},
    prefer_theme: ''
  });
  const {toggle, modes} = props;
  const AuthService = AuthenticationService.getInstance();
  const model = AuthService.getModel();

  useEffect(() => {
    const model_info = model.user_info;
    if (state.prefer_mode.value !== model_info.prefer_mode?.value || state.prefer_theme !== model.prefer_theme) {
      setState({...state, prefer_mode: model_info.prefer_mode, prefer_theme: model_info.prefer_theme});
    }
  }, []);

  const onChangeMode = (value) => {
    let options = model.user_info.hasOwnProperty('id')
      ? {
        action: 'put',
        pk: model.user_info.id,
        prefer_mode: value
      }
      : {
        action: 'post',
        favorite_list: [],
        prefer_mode: value
      }
    AuthService.makeRequest('/api/user_info/', 'POST', options).then(res => {
      AuthService.setState({user_info: res.data});
    })
  }

  function onThemeChange(value){
    const currentPreferTheme = model.user_info.prefer_theme;
    let options = model.user_info.hasOwnProperty('id')
      ? {
        action: 'put',
        pk: model.user_info.id,
        prefer_theme: currentPreferTheme === value ? '' : value
      }
      : {
        action: 'post',
        favorite_list: [],
        prefer_theme: currentPreferTheme === value ? '' : value
      }
    AuthService.makeRequest('/api/user_info/', 'POST', options).then(res => {
      AuthService.setState({user_info: res.data});
      setState({...state, prefer_theme: currentPreferTheme === value ? '' : value});
    })
  }

  return (
    <div className={'SettingsPanel'}>
      <div className="PreferMode__Slider">
        <label htmlFor="">Предпочитаемый режим</label>
        <Slide change={onChangeMode} currentMode={state.prefer_mode} availableModes={modes} notDispatch={true}/>
      </div>
      <div className="PreferTheme__Box">
        <label htmlFor="">Предпочитаемая тема</label>
        <div className={'Slider'}>
          <div className="SliderWrapper">
            {[{title: 'Светлая', value: 'light'}, {title: 'Темная', value: 'dark'}].map(mode => (
              <div key={mode.title}>
                <span className={`SliderOption${state.prefer_theme === mode.value ? ' active' : ''}`}
                      onClick={() => onThemeChange(mode.value)}>
              {mode.title}
            </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
