import React, {useEffect, useState} from 'react';
import './LabeledInput.scss';
import {ThemeService} from "../../../service/ThemeService";

interface IProps {
  title: string,
  onChange?: (value: string | number) => any,
  type?: 'datetime-local' | 'text' | 'number' | 'date' | 'time',
  value: any,
  onClick?: (e: any) => any
}
const LabeledInput = ({title, onChange, type, value, onClick}: IProps) => {
  const [state, setState] = useState({
    theme: 'light'
  });

  const theme = ThemeService.getInstance();

  useEffect(() => {
    setState({...state, theme: theme.getTheme()});
    theme.subscribeUpdates(onThemeChange)
  }, [])

  function onThemeChange(model){
    setState({...state, theme: model});
  }

  return (
    <div className={'LabeledInput__Wrapper'}>
      <label htmlFor={title}>{title}</label>
      <input style={{colorScheme: state.theme}} onClick={onClick} value={value} id={title} type={type ? type : 'text'} onChange={(e) => onChange(e.target.value)}/>
    </div>
  );
};

export default LabeledInput;