import {createSlice} from "@reduxjs/toolkit";
import {AuthenticationService} from "./LoginService";
import moment from "moment/moment";


interface IEvent {
  id?: number,
  important: number,
  location: string,
  members: number[],
  notification: string,
  owner: number,
  tag: {id: number, color: string, title: string},
  title: string,
  freq: boolean,
  freq_config: {freq: string, interval: number, until: string},
  updated: string,
  dt: any,
  created: string,
  comment: string,
  color: string
}

export const EventsService = createSlice({
  name: 'EventsService',
  initialState: <{events: IEvent[]}>{
    events: []
  },
  reducers: {
    setEvents(state, action){
      state.events = action.payload;
      const AuthService = AuthenticationService.getInstance();
      const user = AuthService.getModel().user;
      const userID = user.id;
      const parseDate = (dt: string) => {
        const dtArray = dt.split('T');
        return {date: moment(dtArray[0], 'YYYY-MM-DD').format('DD.MM.YYYY'), time: dtArray[1].slice(0, 5)};
      }
      state.events = action.payload.map((event: any) => {
        const approved = event.members_status[userID];
        return {...event, dt: parseDate(event.dt),
          approved,
          time_end: event?.dt_end?.length > 0 ? parseDate(event.dt_end) : ''}
      })
    },
    setEventsFormatted(state, action) {
      state.events = action.payload;
    }
  }
})

export const {setEvents, setEventsFormatted} = EventsService.actions;

