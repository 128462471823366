import React, {useEffect, useMemo, useState} from 'react';
import './DatePicker.scss';
import moment from "moment";
import {setDaysStore, setDt, setMode} from "../../../service/Date";
import {useDispatch, useSelector} from "react-redux";
import SVG from "../SVG/SVG";
import DaysService from "../../../service/DaysService";
import { cn } from '../../../service/Calendar';
import {RootState} from "../../../store/store";
import {AuthenticationService} from "../../../service/LoginService";
import {setCalendars} from "../../../service/SubscribedService";

interface IState {
  dt: string,
  selectedDt: string,
  daysArray?: {disabled: boolean, dt: string}[]
}

const DateFormat = 'DD.MM.YYYY';

const DatePicker = (props: any) => {
  const [state, setState] = useState<IState>({
    dt: props.dt,
    selectedDt: props.dt,
    daysArray: []
  })
  const AuthService = AuthenticationService.getInstance();
  const mode = useSelector((state: any) => state.dt);
  const holidays = useSelector((state: RootState) => state.holidays).days;
  const dispatch = useDispatch();

  useEffect(() => {
    getDaysInMonth(state.dt)
  }, [mode.mode, state.selectedDt])

  useEffect(() => {
    setState({...state, selectedDt: mode.dt, dt: mode.dt});
  }, [mode.dt])

  useEffect(() => {
    getDaysInMonth(state.dt)
  }, [])

  function getDaysInMonth(dt: string){
    const daysArray = DaysService.calcDays(dt);
    dispatch(setDaysStore(daysArray))
    setState({...state, daysArray});
    return daysArray;
  }

  useMemo(() => {
    loadEvents(state.daysArray);
  }, [state.daysArray])

  function loadEvents(dt){
  return;
    const daysArray = dt.map(d => d.dt).filter((d,i) => i === 0 || i === dt.length - 1).map(d => moment(d, DateFormat).format('YYYY-MM-DD'))
    if (daysArray.length === 0) return;
    AuthService.makeRequest('/api/calendars/', 'POST', {
      action: 'get',
      dt: daysArray
    }).then(res => dispatch(setCalendars(res.data.map((cal: any) => {return {...cal, active: true}}))));
  }

  function decreaseMonth() {
    const {dt} = state;
    const newDt = moment(dt, DateFormat).subtract(1, 'month').format(DateFormat);
    dispatch(setDt(newDt));
    setState({...state, dt: newDt, daysArray: getDaysInMonth(newDt)})
  }
  function increaseMonth() {
    const {dt} = state;
    const newDt = moment(dt, DateFormat).add(1, 'month').format(DateFormat);
    dispatch(setDt(newDt));
    setState({...state, dt: newDt, daysArray: getDaysInMonth(newDt)})
  }
  function selectDay(dt: any, changeMode: boolean){
    dispatch(setDt(dt.dt));
    if (changeMode) dispatch(setMode('day'));
    setState({...state, selectedDt: dt.dt});
  }

  const getHolidays = (day) => {
    return holidays.includes(day) || moment(day, 'DD.MM.YYYY').day() == 0
      || moment(day, 'DD.MM.YYYY').day() == 6
  }

  return(
    <div className={'DatePicker__Wrapper'}>
      <div className="DatePicker__Navigate">
        <div className={'Decrease'} onClick={decreaseMonth}>
          <SVG type={'corner'} width={'0.75rem'} height={'0.75rem'} />
        </div>
        <div className={'CurrentDT'}>
          <span className={'CurrentMonth'} onClick={() => dispatch(setMode('month'))}>
            {(moment(state.dt, 'DD.MM.YYYY').format('MMMM')).toUpperCase()}
          </span>
          <span className={'CurrentYear'} onClick={() => dispatch(setMode('year'))}>
            {(moment(state.dt, 'DD.MM.YYYY').format('YYYY')).toUpperCase()}
          </span>
        </div>
        <div className={'Increase'} onClick={increaseMonth}>
          <SVG type={'corner'} width={'0.75rem'} height={'0.75rem'} />
        </div>
      </div>
      <div className={'DatePicker__Days legend'}>
        <span>ПН</span>
        <span>ВТ</span>
        <span>СР</span>
        <span>ЧТ</span>
        <span>ПТ</span>
        <span>СБ</span>
        <span>ВС</span>
      </div>
      <div className={'DatePicker__Days'}>
        {state.daysArray?.map(dt => (
          <span
              key={dt.dt}
              onClick={() => !dt.disabled && selectDay(dt, state.selectedDt === dt.dt)}
              className={cn('day', {
                disabled: dt.disabled,
                active: dt.dt === state.selectedDt,
                currentDay: moment().format('DD.MM.YYYY') === dt.dt,
                holiday: getHolidays(dt.dt)
              })}
          >{moment(dt.dt, DateFormat).format('DD')}</span>
        ))}
      </div>
    </div>
  );
}

export default DatePicker;
