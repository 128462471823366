import React, {useEffect, useState} from 'react';
import './Members.scss';
import SVG from "../SVG/SVG";
import {AuthenticationService} from "../../../service/LoginService";
import {cn} from "../../../service/Calendar";

export const FormatUsername = (username) => {
  if (!username) return '';
  const firstTwoLetters = username.slice(0, 2).toUpperCase();
  const otherLetters = username.slice(2, username.length);
  return firstTwoLetters + otherLetters;
}

interface IState {
  selectedUsers: IUser[];
  allUserList: IUser[];
  query: string;
  listVisible: boolean;
  favoriteList: number[],
  marked: number[]
}

export interface IUser {
  email: string,
  id: number,
  username: string,
  first_name: string,
  last_name: string,
}


const Mark = ({approved}) => {
  return (
    <svg width="24" height="15" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0002 1.16656L4.00016 9.16656L0.333496
            5.4999L1.2735 4.5599L4.00016 7.2799L11.0602 0.226562L12.0002 1.16656Z"
            fill={`${approved ? '#5fb138' : '#9C9C9C'}`}/>
    </svg>
  );
};


export const Members = ({onChange, list, allUsers, owner}) => {
  const [state, setState] = useState<IState>({
    selectedUsers: [],
    allUserList: [],
    query: '',
    listVisible: false,
    favoriteList: [],
    marked: []
  });

  const AuthService = AuthenticationService.getInstance();

  function onModelChange(model) {
    let fav;
    try {
      fav = JSON.parse(model.user_info.favorite_list)
    } catch (e) {
      fav = []
    }
    setState((state) => {
      return {...state, favoriteList: fav}
    });
  }

  useEffect(() => {
    let fav;
    try {
      fav = JSON.parse(AuthService.getModel().user_info.favorite_list)
    } catch (e) {
      fav = []
    }
    setState((state) => {
      return {...state, favoriteList: fav}
    });
    AuthService.subscribeUpdates(onModelChange);
  }, [])

  const mergeUsers = (array, favoriteList) => {
    const userSort = (a, b) => {
      const a_name = a.first_name.length ? a.first_name : a.username;
      const b_name = b.first_name.length ? b.first_name : b.username;
      return a_name.localeCompare(b_name);
    }

    const allUsersList = array;
    const favoriteUsersId = favoriteList;
    const notFavoriteUsers = allUsersList.filter(user => !favoriteUsersId.includes(user.id)).sort(userSort);
    const favoriteUsersList = favoriteUsersId.map(id => allUsersList.find(user => user.id == id)).sort(userSort);
    const formattedUsers = [...favoriteUsersList, ...notFavoriteUsers];

    setState({...state, allUserList: formattedUsers});
  }

  useEffect(() => {
    AuthService.getUsers().then((res: any) => {
      mergeUsers(
        res.data.filter((us: any) => us.username && us.first_name && us.last_name),
        state.favoriteList)
    })
  }, [state.favoriteList])

  function removeFromList(user) {
    const userList = list.filter(us => us !== user.id);
    setState({...state, selectedUsers: userList});
    onChange(userList);
  }

  function setMarked(user: number) {
    if (state.marked.includes(Number(user))) setState({...state, marked: state.marked.filter(us => us !== user)});
    else setState({...state, marked: [...state.marked, Number(user)]});
  }

  return (
    <div className={'Members'}>
      <div className="Row HeaderRow">
        <label htmlFor="">Участники</label>
        {state.listVisible &&
            <div className="UsersList__Wrapper">
                <div className={'UsersList'}>
                  {state.allUserList.filter(user => !list.includes(user.id) && user.id !== owner).map(user => (
                    <div key={user.id} className={'User'}
                         onClick={() => setMarked(user.id)}>
                      <div className={cn('Checkbox', {
                        marked: state.marked.includes(user.id)
                      })}>
                        <SVG type={'check-mark'} width={'0.8rem'} height={'0.8rem'}/></div>
                      {user.first_name} {user.last_name}</div>
                  ))}
                </div>
            </div>
        }
        {!state.listVisible ?
          <SVG className={'Plus'} type={'plus'} width={'1rem'} height={'1rem'}
               onClick={() => {
                 setState({
                   ...state, listVisible:
                     state.allUserList.filter(el => !state.selectedUsers.map(user => user.id).includes(el.id)).length > 0
                 })
               }}/>
          : <>
                        <span className={'PurpleText'}
                              onClick={() => {
                                let model = Array.from(new Set([...state.selectedUsers,
                                  ...list,
                                  ...state.marked]));
                                setState({
                                  ...state,
                                  selectedUsers: model,
                                  listVisible: false,
                                  marked: []
                                })
                                onChange(model);
                              }}
                        >({state.marked.length}) Добавить</span>
            <span className={'PurpleText'}
                  onClick={() => {
                    setState({...state, listVisible: false, marked: []})
                  }}
                  style={{marginLeft: '0.5rem'}}>Отмена</span>
          </>
        }

      </div>
      <div className="Row">
        <div className="SelectedList">
          {list.length > 0 && list.map(id => allUsers.find(user => user.id == id || user.id == id?.id)).map(user => (
            <div className={'SelectedUser'}>
              {user?.id !== owner &&
                  <SVG onClick={() => removeFromList(user)} className={'TrashIcon'} type={'trash'} width={'1.5rem'}
                       height={'1.5rem'}/>
              }
              {FormatUsername(user?.username)}
              <Mark approved={false}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Members;