import React, {useEffect, useState} from 'react';
import './Day.scss'
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import EventBox from "../EventBox/EventBox";
import ModalInformation from "../Month/ModalInformation";
import {
  clearForm,
  setCurrentDt,
  setCurrentEvent,
  setEndDt, setForm,
  setStartDt,
  setVisible
} from "../../../../service/EditFormService";
import {RootState} from "../../../../store/store";
import {getEventsColumn, showDetails} from "../../../../service/Calendar";
import {DateFormat} from "../../../../service/DaysService";

type week = Array<{ dt: string, disabled: boolean }>
type propsDay = {
  selected: { dt: string, daysStore: Array<{ dt: string, disabled: boolean }>, mode: week },
  freqEvents: any,
  events: any,
  calendars: any
}
const Day = (props: propsDay) => {
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  const [state, setState] = useState<{
    events: any,
    selection: boolean,
    startEvent: any,
    endEvent: any,
    isModalVisible: boolean,
    selected: { dt: string },
    currentEvent: any,
    formSelected: boolean,
    calendarEvents: any[]
  }>
  ({
    events: [],
    selection: false,
    startEvent: '',
    endEvent: '',
    isModalVisible: false,
    selected: {dt: props.selected.dt},
    currentEvent: undefined,
    formSelected: false,
    calendarEvents: []
  })

  const [coord, setCoord] = useState({x: 0, y: 0})
  const form = useSelector((state: RootState) => state.editForm);

  const dispatch = useDispatch();

  useEffect(() => {
    setState({...state, selected: {dt: props.selected.dt}, currentEvent: undefined})
  }, [props.events, props.selected, props.calendars])


  function closeModal() {
    setState({...state, isModalVisible: false, currentEvent: null, startEvent: '', endEvent: ''})
  }

  function newEvent(hour: number){
    const makeHour = (hourTime: number) => (hourTime < 10 ? '0' + String(hourTime) : String(hourTime)) + ':00';
    const [startDt, endDt] = [makeHour(hour), makeHour(hour + 1)];

    const options = {
      ...state,
      currentEvent: undefined,
      startEvent: startDt,
      endEvent: endDt,
      selected: {dt: props.selected.dt}
    }

    dispatch(setForm({
      startDt: options.selected.dt + ' ' + options.startEvent,
      endDt: options.selected.dt + ' ' + options.endEvent,
      currentDt: options.selected.dt,
      currentEvent: null,
      visible: true,
      mode: 'new'
    }))

    setState(options);
  }

  function getEventsForDay(dt){
    return (props.events[dt] ? [...props.events[dt]] : [])
      .concat(props.freqEvents[dt])
      .concat(props.calendars[dt]);
  }

  return (
      <>
        {state.isModalVisible ?
            <ModalInformation close={closeModal} coord={coord} event={state.currentEvent} />
            : <></>
        }
        <div className={'DayBox'}>
          <div className="DayGrid__Header">
            <span>{props.selected.dt}</span>
          </div>
          <div className="Grid">
            <div className="HoursWrapper">
              {hours.map((hour, i) => (
                  <div className={'HourRow'} key={i}><span className={'Hour'}>{hour}:00</span></div>
              ))}
            </div>
            <div className="DayGrid">
              {hours.map((hour) => (
                  <div className={'GridRow'} key={hour}>
                    <div className={'Background'} onClick={() => newEvent(hour)}></div>
                    {getEventsColumn(hour, props.selected.dt, getEventsForDay(props.selected.dt), form,
                      (ev, i, currentHourEvents) =>
                        <div
                          onClick={(e) => showDetails(e, ev, state.currentEvent,
                            (visible, x, y) => {
                              setState({...state, isModalVisible: visible, currentEvent: visible ? ev : null});
                              setCoord({...state, x, y});
                            }
                          )}
                          className={`GridTimeColumn`}
                          style={{height: ev.height + ev.height / 100 * 6.5 + '%',
                            position: 'absolute',
                            width: 95 / currentHourEvents.length - 1 + '%',
                            left: (i * 95 / currentHourEvents.length) + '%',
                            top: ev.top
                          }}
                          key={Math.random()}>
                          <EventBox className={'Week'}
                                    event={ev}
                                    formEvent={ev.previewOnly}
                          />
                        </div>
                    )}
                  </div>
              ))}
            </div>
          </div>
        </div>
      </>
  )
}

export default Day;
