import React, {useEffect, useState} from 'react';
import './Filter.scss';
import {useDispatch, useSelector} from "react-redux";
import Svg from "../../SVG/SVG";
import {setEventsFormatted} from "../../../../service/EventsService";
import Search from "./Search";
import {RootState} from "../../../../store/store";
import TuneIcon from "@mui/icons-material/Tune";
import TagModal from "./TagModal";


const Filter = () => {
  const [state, setState] = useState<{
    tags: any[],
    expanded: boolean,
    tmp_events: any[],
    editTag: any,
    coord: {x: number, y: number}
  }>({
    tags: [],
    expanded: true,
    tmp_events: [],
    editTag: null,
    coord: {x: 0, y: 0}
  })
  const [stateEvents, setStateEvents] = useState([]);
  const events = useSelector((state: any) => state.events).events;
  const tags = useSelector((state: RootState) => state.tags).tags;
  const dispatch = useDispatch();

  useEffect(() => {
    if (stateEvents.length === 0 || events.length > stateEvents.length) setStateEvents(events)
  }, [events, tags])

  useEffect(() => {
    setState({...state, tags: tags.map((tag) => {return {...tag, visible: true, active: true}})})
  }, [tags])

  function filterEvents(tags: any){
    const tagsTitle = tags.filter((tag: any) => tag.active)
        .map((tag: any) => tag.title.toLowerCase() === 'Без тэга'.toLowerCase() ? '' : tag.title.toLowerCase());
    const filteredEvents = stateEvents.filter((event:any) => tagsTitle.includes(event.tag.title.toLowerCase()));
    dispatch(setEventsFormatted(filteredEvents))
  }

  function toggleFilter(tag: any){
    const tags = state.tags.map((tagState: any) => {
      if (tagState.id === tag.id) return {...tag, active: !tag.active}
      else return tagState;
    })
    setState({...state, tags})
    filterEvents(tags)
  }

  function onSearchChange(e){
    const value = e.target.value || '';
    const newTags = state.tags.map(list => {
      return {...list, visible: list.title.toLowerCase().match(value.toLowerCase())}
    });
    setState({...state, tags: newTags});
  }

  function openModal(e, tag){
    const [x, y] = [e.clientX, e.clientY];
    setState({...state, coord: {x, y}, editTag: tag});
  }

  return (
      <div className={`FilterWrapper${state.expanded ? ' active' : ''} Tag`}>
        {state.editTag &&
          <TagModal close={() => setState({...state, editTag: null})} coord={state.coord} tag={state.editTag} />
        }
        <div className={`FilterInput`} onClick={() => setState({...state, expanded: !state.expanded})}>
          <div className={'Corner'}>
            <Svg height={'0.75rem'} width={'0.75rem'} type={'corner'} />
          </div>
          <span className={'FilterTitle'}>Теги</span>
        </div>
        {state.expanded &&
            <>
              <Search onChange={onSearchChange} />
              <div className="FilterBody">
                {state.tags.sort((a,b) => a.title.localeCompare(b.title)).filter(list => list.visible).map((tag: any) => (
                    <div key={Math.random()} className={'TagCheckbox'}>
                      <div style={{
                        backgroundColor: tag.color || '#838fce',
                        border: '1px solid ' + (tag.color || '#838fce')
                      }} className={`CheckBox${!tag.active ? ' off' : ''}`}
                           onClick={() => toggleFilter(tag)}>
                        {tag.active && <Svg height={'1rem'} width={'1rem'} type={'check-mark'}/> }
                      </div>
                      <span className={'TagTitle'} onClick={() => toggleFilter(tag)}>{tag.title}</span>
                      <TuneIcon onClick={(e) => openModal(e, tag)} color={'action'} />
                    </div>
                ))
                }
              </div>
            </>}
      </div>
  );
};

export default Filter;
