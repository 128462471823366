import React, {useEffect, useState} from 'react';
import './Filter.scss';
import {useDispatch, useSelector} from "react-redux";
import Svg from "../../SVG/SVG";
import {setCalendars} from "../../../../service/SubscribedService";
import TuneIcon from '@mui/icons-material/Tune';
import {Button, Dialog, DialogActions, DialogContent, TextField} from "@mui/material";
import {AuthenticationService} from "../../../../service/LoginService";
import CalendarModal from "./CalendarModal";

const Filter = () => {
  const [state, setState] = useState<{
    calendars: any[],
    events: any[],
    expanded: boolean,
    isModalVisible: boolean,
    currentCalendar?: any,
    error: any,
    coord: {x: number, y: number},
    isLoading: boolean
  }>({
    calendars: [],
    events: [],
    expanded: true,
    isModalVisible: false,
    error: '',
    coord: {x: 0, y: 0},
    isLoading: false
  })
  const [ref, setRef] = useState({
    element: null
  });
  const calendars = useSelector((state: any) => state.subscribed).calendars;
  const dispatch = useDispatch();
  const AuthService = AuthenticationService.getInstance();

  useEffect(() => {
    setState({...state, calendars})
  }, [calendars])

  function toggleFilter(calendar: any) {
    const newCalendars = state.calendars.map((cal: any) => {
      if (cal.id === calendar.id) return {...cal, active: !cal.active}
      else return cal;
    })
    dispatch(setCalendars(newCalendars))
  }

  const toggleModal = (e, calendar?: any) => {
    console.log(ref)
    setState({
      ...state,
      isModalVisible: !state.isModalVisible,
      currentCalendar: calendar,
      error: '',
      coord: {x: e.clientX, y: e.clientY}
    });
  }

  const closeModal = () => setState({...state, isModalVisible: false});

  function editCalendar() {
    setState({...state, isLoading: true})
    AuthService.makeRequest('/api/calendars/', 'POST', {
      action: 'put',
      pk: state.currentCalendar.id,
      title: state.currentCalendar.title,
      link: state.currentCalendar.link,
      color: state.currentCalendar.color
    }).then((res: any) => {
      const cal = res.data.map((cal: any) => {
        return {...cal, active: true}
      })
      dispatch(setCalendars(cal));
      return cal;
    }).then((cal) => {
      closeModal();
      setState({...state, calendars: cal, isModalVisible: false, isLoading: false})
    }).catch(e => {
      setState({...state, error: e.response?.data?.detail, isLoading: false})
    })
  }

  function removeCalendar() {
    AuthService.makeRequest('/api/calendars/', 'POST', {
      action: 'delete',
      pk: state.currentCalendar.id,
    }).then((res: any) => {
      dispatch(setCalendars(res.data.map((cal: any) => {
        return {...cal, active: true}
      })));
    })
    closeModal()
  }

  if (state.calendars.length === 0) return (<></>);

  return (
    <div className={`FilterWrapper${state.expanded ? ' active' : ''}`}>
      <div className={`FilterInput`} onClick={() => setState({...state, expanded: !state.expanded})}>
        <div className={'Corner'}>
          <Svg height={'0.75rem'} width={'0.75rem'} type={'corner'}/>
        </div>
        <span className={'FilterTitle'}>Подписки</span>
      </div>
      {state.isModalVisible &&
          <CalendarModal
              color={state.currentCalendar.color}
              onClose={closeModal}
              coord={state.coord}
              title={state.currentCalendar.title}
              link={state.currentCalendar.link}
              remove={removeCalendar}
              apply={editCalendar}
              isLoading={state.isLoading}
              onChange={(model) => {
                setState({...state, currentCalendar: {...state.currentCalendar, ...model}})}}
          />
      }
      {state.expanded &&
          <div className="FilterBody">
            {state.calendars.map((calendar: any) => (
              <div className={'TagCheckbox'}>
                <div
                  onClick={() => toggleFilter(calendar)}
                  style={{backgroundColor: calendar.color, borderColor: calendar.color}}
                  className={`CheckBox${!calendar.active ? ' off' : ''}`}>
                  {calendar.active && <Svg height={'0.75rem'} width={'0.75rem'} type={'check-mark'}/>}
                </div>
                <span onClick={() => toggleFilter(calendar)}>{calendar.title}</span>
                <TuneIcon color={'action'} onClick={(e) => toggleModal(e, calendar)}/>
              </div>
            ))}
          </div>
      }
    </div>
  );
};

export default Filter;
