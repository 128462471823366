import React, {useEffect, useState} from 'react';
import './Month.scss';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {setDt, setMode} from "../../../../service/Date";
import {setCurrentDt, setCurrentEvent, setFormMode, setVisible} from "../../../../service/EditFormService";
import ModalInformation from "./ModalInformation";
import EventBox from "../EventBox/EventBox";
import {RootState} from "../../../../store/store";
import {cn, daysWeek, showDetails} from "../../../../service/Calendar";


interface IEvent {
  id?: number,
  important: number,
  location: string,
  members: number[],
  notification: string,
  owner: number,
  tag: string,
  title: string,
  updated: string,
  freq: boolean,
  dt: string | {date: string, time: string},
  created: string,
  comment: string,
  color: string,
  editable?: boolean,
}


const Month = ({freqEvents}) => {
  const [state, setState] = useState<{
    isModalVisible: boolean,
    selected: { dt: string },
    coord: { x: number, y: number },
    eventsList: IEvent[],
    currentEvent?: IEvent,
    selected_event: IEvent[],
    readonly: boolean,
    calendarEvents: any[]
  }>({
    currentEvent: undefined,
    isModalVisible: false,
    selected: {dt: ''},
    coord: {x: 0, y: 0},
    eventsList: [],
    selected_event: [],
    readonly: false,
    calendarEvents: []
  })

  const [selectedDay, setSelected] = useState('')

  const daysStore = useSelector((state: any) => state.dt.daysStore);
  const selected = useSelector((state: any) => state.dt);
  const dispatch = useDispatch();
  const events = useSelector((state: any) => state.events).events;
  const calendars = useSelector((state: any) => state.subscribed).calendars;
  const tags = useSelector((state: RootState) => state.tags).tags;
  const holidays = useSelector((state: RootState) => state.holidays).days;
  const form = useSelector((state: RootState) => state.editForm.model);
  const formMode = useSelector((state: RootState) => state.editForm.mode);

  useEffect(() => {
    setState({...state, eventsList: events.map(ev => {
        if (typeof(ev.tag) === 'number'){
          return {...ev, tag: tags.find(tag => tag.id === ev.tag)}
        }
        else return {...ev, tag: tags.find(tag => tag.id === ev.tag.id)};
      }), calendarEvents: calendars.filter((cal: any) => cal.active)
        .reduce((prev: any, curr: any) => prev.concat(curr.events), []), currentEvent: undefined, readonly: false});
  }, [events, calendars, tags]);

  function addEvent(dt: any, e: any, event?: IEvent) {
    dispatch(setVisible(true));
    dispatch(setCurrentDt(dt.dt));
    if (event !== undefined) dispatch(setCurrentEvent(event));
    else {
      dispatch(setCurrentEvent(null));
      dispatch(setFormMode('new'));
    }
    setSelected(dt.dt);
    setState({...state, selected: dt, readonly: event === undefined ? false : !event?.editable,
      currentEvent: event});
  }

  function choiceDay(dt:string) {
    dispatch(setMode('day'));
    dispatch(setDt(dt));
  }


  function setDay(dt:string) {
    dispatch(setDt(dt));
  }

  const closeModal = () => setState({...state, isModalVisible: false, currentEvent: null});

  function sortInSector(a, b){
    const toNumber = (time) => Number(time?.replace(':', '')) || 0;
    return toNumber(a.dt.time) - toNumber(b.dt.time);
  }


  const getEvents = (dt) => {
    const currentDayFreqEvents = freqEvents[dt.dt];
    let events = state.eventsList.filter(ev => !ev.freq).concat(currentDayFreqEvents);
    if (formMode === 'new') events = events.concat([
      {
        ...form,
        dt: {date: moment(form?.dt, 'YYYY-MM-DD').format('DD.MM.YYYY')},
        previewOnly: true
      }
    ])
    return events.filter((event: any) => event?.dt.date === dt.dt || (event?.freq && !event.previewOnly)).sort(sortInSector)
  }


  return (
      <>
        <div className={'WeekDays'}>
          {[0,1,2,3,4,5,6].map(el => (el < 7 ? <span className={'DayWeek'}>{daysWeek[el]}</span> : ''))}
        </div>
        <div className={'GridMonth'}>
          {state.isModalVisible ?
              <ModalInformation close={closeModal} coord={state.coord} event={state.currentEvent} />
              : <></>
          }
          {daysStore?.map((dt: any, i: number) => (
              <div className={cn('GridSector', {
                weekend: moment(dt.dt, 'DD.MM.YYYY').day() == 0
                  || moment(dt.dt, 'DD.MM.YYYY').day() == 6
                || holidays.includes(dt.dt),
                selected: selectedDay === dt.dt
              })}
                key={Math.random()}>

                <span
                    key={Math.random()}
                    onDoubleClick={() => dt.disabled ? null : choiceDay(dt.dt)}
                    onClick={() => dt.disabled ? null : setDay(dt.dt)}
                    className={cn('GridDT', {
                      active: dt.dt === selected.dt,
                      disabled: dt.disabled
                    })}>
                      <span className={'DayNumber'}>{moment(dt.dt, 'DD.MM.YYYY').format('DD')}</span>
                </span>

                <div className={'GridSector__Back'} onClick={(e) => addEvent(dt, e)}></div>

                <div className="GridBox">
                  {getEvents(dt).map((event: any) =>
                    <EventBox event={event} onClick={(e) => !event.hidden &&
                      showDetails(e, event, state.currentEvent,
                        (visible, x, y) => {
                          setState({
                            ...state,
                            isModalVisible: visible,
                            currentEvent: visible ? event : null,
                            coord: {x, y}
                          })
                        }
                      )
                    }
                              formEvent={event.previewOnly}
                    />
                  )}

                  {state.calendarEvents.filter(ev => !ev.freq).filter((event: any) => event?.dt.date === dt.dt).map((event: any) => (
                      <EventBox event={event} onClick={(e) => !event.hidden &&
                        showDetails(e, event, state.currentEvent,
                          (visible, x, y) =>
                            setState({
                              ...state,
                              isModalVisible: visible,
                              currentEvent: visible ? event : null,
                              coord: {x, y}
                            })
                        )
                      }/>
                  ))}
                </div>
              </div>
          ))}
        </div>
      </>
  );
}

export default Month;