import React, {useEffect, useState} from 'react';
import './ModalInformation.scss';
import moment from "moment";
import {AuthenticationService} from "../../../../service/LoginService";
import SVG from "../../SVG/SVG";
import $ from 'jquery';
import {useDispatch, useSelector} from "react-redux";
import {setEvents} from "../../../../service/EventsService";
import {setCurrentEvent, setFormMode, setVisible} from "../../../../service/EditFormService";
import {cn} from "../../../../service/Calendar";
import {FormatUsername} from "../../EditForm/Members";
import {RootState} from "../../../../store/store";
import {DateFormat} from "../../../../service/DaysService";

const InformationBox = ({type, field, event}) => {
  if ((event[field] && event[field].length === 0) || !event[field]) return (<></>);

  const daysWeek = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
  const dtString = moment(event?.dt?.date, 'DD.MM.YYYY').format('DD.MM');
  const dayofweek = daysWeek[moment(event.dt.date, 'DD.MM.YYYY').day()];
  const dt = dayofweek + ', ' + dtString + ',  ';
  const importantTitles = ['Обычный приоритет', 'Средний приоритет', 'Выше среднего', 'Высокий приоритет']
  const AuthService = AuthenticationService.getInstance();
  const [state, setState] = useState({
    dt: '',
    userList: [],
    owner: {username: ''},
    members: []
  });

  useEffect(() => {
    if (type === 'members') {
      AuthService.getUsers().then(res => {
        const users = res.data;
        const owner = users.find(user => user.id === event.owner);
        const membersKeys = Array.from(Object.keys(event.members_status))
          .filter(el => Number(el) !== owner.id).map(id => Number(id));
        setState({
          ...state,
          userList: users,
          owner,
          members: membersKeys
        })
      });
    }
  }, [event])

  const getDtTitle = () => `${event.dt.time} - ${event.time_end.time}`

  function getInformation() {
    switch (field) {
      case 'dt':
        return (<>
          <div className="dt" style={{marginRight: '0.5rem'}}>{dt}</div>
          <div className="dt">{getDtTitle()}</div>
        </>)
      case 'important':
        return <>{importantTitles[event.important]}</>;
      case 'members':
        if (event.calendar) {
          const userList = AuthService.getModel().usersList;
          const membersList = Array.isArray(event.members) ? event.members : [];
          const findUser = (userMail) => {
            const userName = userList.find(user => user.email === userMail)?.username;
            if (userName) return FormatUsername(userName);
            else return userMail;
          }
          const owner = findUser(event.owner);
          const usernames = membersList.map(findUser).filter(user => user !== owner);

          return <div className="user owner">
            <div className="Member">
              {owner}
              <SVG type={'owner'} width={'1rem'} height={'1rem'}/>
            </div>
            {usernames.map(mail =>
              <div className="Member">
                {mail}
                <SVG type={'green-mark'} width={'max-content'} height={'max-content'}/>
              </div>)}</div>;
        }
        else {
          return <div className="user owner">
            <div className="Member">
              {FormatUsername(state.owner.username)}
              <SVG type={'owner'} width={'1rem'} height={'1rem'}/>
            </div>
            {
              state.members.map(key => {
                const approved = event.members_status[key] ? 'green-mark' : 'grey-mark';
                return (
                  <div className={cn('Member', {[approved]: approved})}>
                    {FormatUsername(state.userList.find(user => user.id == key)?.username)}
                    {approved === 'green-mark' ?
                      <SVG type={'green-mark'} width={'max-content'} height={'max-content'}/> : ''}
                  </div>
                )
              })
            }</div>;
        }
      case
      'comment'
      :
        return <>{event[field] || event['description']}</>
      case
      'freq'
      :
        const freqType = event.freq_config.freq;
        let frequency;
        if (freqType === 'weekly' && (!event.freq_config.interval || event.freq_config.interval === 1)) frequency = 'Еженедельно';
        if (freqType === 'weekly' && event.freq_config.interval > 1) frequency = `Каждые ${event.freq_config.interval} нд.`
        if (freqType === 'daily' && (!event.freq_config.interval || event.freq_config.interval === 1)) frequency = 'Ежедневно';
        if (freqType === 'daily' && event.freq_config.interval > 1) frequency = `Каждые ${event.freq_config.interval} дн.`
        if (event.freq_config.until) frequency += ` до ${moment(event.freq_config.until, 'YYYY-MM-DD').format(DateFormat)}`
        return <>{frequency}</>
      default:
        return <>{event[field]}</>
    }
  }

  return (
    <div className={'InformationBox'}>
      <div className="InformationBox__Icon">
        <SVG type={type} height={'1.65rem'} width={'1.65rem'}/>
      </div>
      <div className="InformationBox__Details">
        {getInformation()}
      </div>
    </div>
  )
}

const ModalInformation = ({
                            event,
                            coord,
                            close
                          }: { event: any, coord: { x: number, y: number }, close: () => void }) => {
  if (!event) return (<></>);
  if (event && !event.members) event = {...event, members: []};

  const subscrubed = event.hasOwnProperty('calendar');

  const [state, setState] = useState({
    dt: '',
    userList: [],
    owner: {username: ''},
    members: [],
  });
  const [newCoord, setNewCoord] = useState({x: 0, y: 0})
  const [ref, setRef] = useState<HTMLDivElement>();

  const AuthService = AuthenticationService.getInstance();
  const model = AuthService.getModel();
  const dispatch = useDispatch();

  const mode = () => {
    try {
      if (event.approved && event.owner !== model.user.id) return 'view';
      else if (event.approved && event.owner === model.user.id) return 'edit';
      else if (!event.approved && event.owner !== model.user.id && !event.members?.includes(model?.user?.id)) return 'view'
      else return 'approve';
    } catch (e) {return 'view'}
  } // 'edit' - редактирование | 'approve' - подтвердить | 'view' - просмотр;

  const daysWeek = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

  useEffect(() => {
    const dayofweek = daysWeek[moment(event.dt.date, 'DD.MM.YYYY').day()];
    const dtString = moment(event.dt.date, 'DD.MM.YYYY').format('DD.MM');
    AuthService.getUsers().then(res => {
      const users = res.data;
      const owner = users.find(user => user.id === event.owner);
      const membersKeys = Array.from(Object.keys(event.members_status))
        .filter(el => Number(el) !== owner.id).map(id => Number(id));
      setState({
        ...state,
        dt: dayofweek + ', ' + dtString + ',' + moment(event.dt.time, 'HH:mm').format('HH'),
        userList: users,
        owner,
        members: membersKeys
      })
    });

  }, [event])


  function openEditForm() {
    dispatch(setVisible(true));
    dispatch(setCurrentEvent(event));
    dispatch(setFormMode('edit'));
    close();
  }

  function updateStatus(status: string) {
    const getMembersStatus = () => {
      if (status === 'accept') return {...event.members_status, [model.user.id]: true};
      else if (status === 'decline') {
        const opt = {...event.members_status}
        delete opt[model.user.id]
        return opt
      }
    }
    AuthService.makeRequest('/api/approve/', 'POST', {
      action: 'put',
      pk: event.id,
      status: status,
      members_status: getMembersStatus(),
      members: Array.from(Object.keys(getMembersStatus()))
    }).then(res => {
      dispatch(setEvents(res.data));
      close()
    })
  }

  async function removeEvent() {
    AuthService.makeRequest('/api/events/', 'POST', {
      action: 'DELETE',
      pk: event?.id,
    }).then((res: any) => {
      dispatch(setEvents(res.data));
    });
  }

  function renderButtons(mode: string) {
    switch (mode) {
      case 'approve':
        return (<>
          <span onClick={() => updateStatus('decline')}>Отклонить</span>
          <span onClick={() => updateStatus('accept')}>Принять</span>
        </>)
        break;
      case 'edit':
        return (<>
          <span onClick={removeEvent}>Удалить</span>
          <span onClick={() => openEditForm()}>Редактировать</span>
        </>)
      case 'view':
        if (event.calendar) return <></>
        return <span onClick={() => updateStatus('decline')}>Покинуть</span>
    }
  }


  useEffect(() => {
    const height = $('.ModalInformation').height();
    const width = $('.ModalInformation').width();
    const documentWidth = $('body').width()
    const documentHeight = $('body').height()
    if (coord.x + width > documentWidth) {
      coord.x = documentWidth - width - 20;
    }
    if (coord.y + height > documentHeight) {
      coord.y = documentHeight - height - 20;
    }
    setNewCoord({...newCoord, x: coord.x, y: coord.y});
  }, [coord, ref])

  if (newCoord.y < 50 || newCoord.x < 50) return (<></>)

  return (
    <div className={'ModalInformation'} ref={(ref) => setRef(ref)} style={{top: newCoord.y, left: newCoord.x}}>
      <div className={'Wrapper'}>
        <SVG onClick={() => close()}
             className={'CloseIcon'} type={'cross'} width={'max-content'} height={'max-content'}/>
        <div className="buttons-group">
          {renderButtons(mode())}
        </div>
        <div className="row-box">
          <div className="colorBox"
               style={{backgroundColor: event.hasOwnProperty('calendar') ? event.color : event.tag?.color}}></div>
          <div className="Title">{event.title || '(Без названия)'}</div>
        </div>
        {[
          {field: 'dt', type: 'calendar-icon'},
          {field: 'freq', type: 'freq'},
          {field: 'important', type: 'important'},
          {field: 'location', type: 'location'},
          {field: 'members', type: 'members'},
          {field: subscrubed ? 'description' : 'comment', type: 'comment'}
        ].map(f => <InformationBox key={f.field} field={f.field} type={f.type} event={event}/>)}
      </div>
    </div>
  );
};

export default ModalInformation;