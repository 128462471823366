import React, {useEffect, useState} from 'react';
import './FunctionalPanel.scss';
import HeaderPanel from "../components/HeaderPanel/HeaderPanel";
import LeftPanel from "../components/LeftPanel/LeftPanel";
import Grid from "../components/Grid/Grid";
import Push from "../components/Push/PushPanel";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import EditForm from "../components/EditForm/EditForm";

export interface IMode {
  value: string;
  title: string;
}

interface IFPanelState {
  pushVisible: boolean,
  small: boolean,
  editFormVisible: boolean,
}

const FunctionalPanel = (props?: any) => {
  const [state, setState] = useState<IFPanelState>({
    pushVisible: false,
    small: false,
    editFormVisible: false
  })

  const {logout} = props;

  const editForm = useSelector((state: RootState) => state.editForm);

  const changeMode = (mode: IMode) => {setState({...state})};
  let push = useSelector((state: {push: {details: string[]}}) => state.push).details;

  useEffect(() => {
    setState({...state, pushVisible: push.length > 0})
  }, [push]);

  useEffect(() => {
    setState({...state, editFormVisible: editForm.visible})
  }, [editForm])

  return (
      <div className={'FPanel__Wrapper'}>
        <div style={{position: 'relative', height: '100%'}}>
          {
            state.pushVisible &&
            <div className={'PushContainer__Wrapper'}>
              {push.map((detail: any) => (
              <Push detail={detail} />
              ))}
            </div>
          }
        <div className={'HeaderPanel'}>
          <HeaderPanel logout={logout} change={changeMode} />
        </div>
        <div className={'BodyRow'} style={{width: '100%', height: '100%'}}>
          <div className={`LeftPanel ${state.small ? 'collapsed' : ''}`}>
            <LeftPanel />
          </div>
          <div className={`GridWrapper${state.editFormVisible ? ' small' : ''}`}>
            <Grid />
          </div>
          {state.editFormVisible &&
              <div className={'EditForm'}>
                <EditForm />
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default FunctionalPanel
