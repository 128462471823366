import React, {useEffect, useMemo, useState} from 'react';
import './CalendarModal.scss';
import ColorPalette from "./ColorPalette";
import $ from "jquery";

const CalendarModal = ({coord, onClose, title, link, remove, apply, onChange, isLoading, color}) => {
  const [coordinates, setCoord] = useState({
    x: 0,
    y: 0
  });

  let refEl = null;

  useEffect(() => {
    const crd = {x: coord.x, y: coord.y}
    const height = $(refEl).height();
    const width = $(refEl).width();
    const documentWidth = $(document).width()
    const documentHeight = $(document).height()
    if (crd.x + width + 5 >= documentWidth) {
      crd.x = documentWidth - width - 20;
    }
    if (crd.y + height + 40 >= documentHeight) {
      crd.y = documentHeight - height - 40;
    }
    setCoord({...coordinates, ...crd})
  }, [refEl])

  function setRefElement(ref){
    if (!ref) return;
    else {
      refEl = ref;
    }
    return;
  }

  return (
    <>
      <div onClick={onClose} className="CalendarModal__Overlay"></div>
      <div ref={setRefElement} className={'CalendarModal__Wrapper'}
           style={{
             position: 'absolute',
             top: coordinates.y,
             left: coordinates.x,
             opacity: coordinates.x === 0 || coordinates.y === 0 ? 0 : 1
      }}>
        <div className="CalendarModal__Row">
          <label htmlFor={'Name'}>Название</label>
          <input value={title} onChange={(e) => {onChange({title: e.target.value})}} id={'Name'} type="text"/>
        </div>
        <div className="CalendarModal__Row">
          <label htmlFor={'link'}>Ссылка</label>
          <input onChange={(e) => {onChange({link: e.target.value})}} value={link} id={'link'} type="text"/>
        </div>
        <ColorPalette currentColor={color} onChange={(color) => onChange({color})} calendar={true} />
        <div className="buttonsGroup">
          {isLoading && <span className="_loader"></span>}
          <div className={'Buttons'}>
            <div onClick={remove}>Удалить</div>
            <div onClick={apply}>Сохранить</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarModal;