import moment from "moment";
import {IEvent} from "../views/components/Grid/Modal/Modal";
import {DateFormat} from "./DaysService";

const calendarService = {
    cn: (className, attributes) => {
        let keys = Array.from(Object.keys(attributes));
        let str = keys.reduce((prev, curr) => {
            if (attributes[curr]) return prev += ` ${curr}`;
            else return prev;
        }, '')
        return className + str;
    },
    daysWeek: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье '],
    hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    getEventsColumn: (hour: number, dt, events, form, callback): any => {
        if (!events || !events.length) return;

        const filterEvents = (ev) => {
            if (!ev) return;
            return moment(ev.dt.time, 'HH:mm').hour() == hour
        }
        let currentHourEvents = events.filter(filterEvents).map((ev: any) => {
            const duration = Math.abs(moment(ev.dt.time, 'HH:mm').diff(moment(ev.time_end.time, 'HH:mm'), 'minutes'));
            const top = ((+moment(ev.dt.time, 'HH:mm').format('mm') / 60) || 0) * 100 + '%';
            return {...ev, height: Math.round((duration / 60) * 100), top}
        });

        const formValues = form?.startDt?.split(' ')?.[1];
        const formDT = {
            hour: Number(formValues?.split(':')?.[0]),
            minutes: formValues?.split(':')?.[1]
        }

        if (form.currentDt === dt && formDT.hour === hour && form.mode === 'new'){
            currentHourEvents = currentHourEvents.concat([{
                title: '',
                important: 0,
                tag: '',
                height: 100,
                calendar: null,
                approved: false,
                previewOnly: true
            }])
        }
        return currentHourEvents.map((ev, i) => callback(ev, i, currentHourEvents))
    },
    showDetails: (e, event, currentEvent, callback) => {
        const [x, y] = [e.clientX, e.clientY];
        let visible = currentEvent?.id !== event.id;
        callback(visible, x, y);
    }
}

export const {cn, daysWeek, hours, getEventsColumn, showDetails} = calendarService;
