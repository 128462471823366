import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from "./views/App/App";
import {store} from "./store/store";
import $ from 'jquery';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


function synchronizeRem() {
  let height = $('html').height() || 0;
  const myRem = (height / 100 * 1.5).toFixed(2) + 'px';
  $('html').css('font-size', myRem)
  $('html').attr('rem-size', (height / 100 * 1.5).toFixed(2))
}

window.onresize = synchronizeRem;
synchronizeRem()

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
